import { Autocomplete, TextField, TextFieldProps } from "@mui/material";
import { BaseQueryFn, TypedUseLazyQuery } from "@reduxjs/toolkit/dist/query/react";
import { AxiosRequestConfig } from "axios";
import { SyntheticEvent, useEffect, useState } from "react";

declare type Data = { [key: string]: string | number | boolean | null | undefined; };

interface AutocompleteLazyQueryProps<D extends Data, QueryArg> {
    query: TypedUseLazyQuery<D[], QueryArg, BaseQueryFn<AxiosRequestConfig<D[]>>>;
    queryArg: QueryArg;

    displayField: keyof D;
    valueField: keyof D;
    defaultValue?: D[AutocompleteLazyQueryProps<D, QueryArg>['valueField']];

    name?: TextFieldProps['name'];
    label?: TextFieldProps['label'];
    TextField?: TextFieldProps;
    onChangeValue?: (item: D | null) => void;
    disabled?: boolean;
}


export function AutocompleteLazyQuery<D extends Data, QueryArg>({ displayField, valueField, defaultValue, onChangeValue, ...props }: AutocompleteLazyQueryProps<D, QueryArg>) {
    const [execQuery, result] = props.query();
    const [options, setOptions] = useState<D[]>([]);

    const [selectedValue, setSelectedValue] = useState<D | null>(null);
    const [inputValue, setInputValue] = useState<string>('');

    useEffect(() => {
        execQuery(props.queryArg);
    }, [execQuery, props.queryArg]);

    useEffect(() => {
        if (!result.data)
            return;

        setOptions(result.data);
        if (defaultValue) {
            const selected = result.data.find((it) => it[displayField] === defaultValue) || null;
            setSelectedValue(selected);
        }
    }, [defaultValue, displayField, result.data]);

    useEffect(() => {
        if (!onChangeValue)
            return;
        onChangeValue(selectedValue);
    }, [onChangeValue, selectedValue]);

    return (
        <Autocomplete
            disablePortal
            fullWidth
            loading={result.isLoading || result.isFetching}
            options={options}
            getOptionLabel={(item: D) => item[displayField] as string}
            getOptionKey={(item: D) => item[valueField] as string}
            value={selectedValue}
            onChange={(_: SyntheticEvent, newValue: D | null) => setSelectedValue(newValue)}
            inputValue={inputValue}
            disabled={props.disabled}
            onInputChange={(_: SyntheticEvent, newInputValue: string) => setInputValue(newInputValue)}
            size="small"
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={props.name}
                    label={props.label}
                    {...props.TextField}
                />
            )}
        />
    );
}