import { LatLng, LatLngLiteral } from "leaflet";
import { ReactNode, useRef, useCallback } from "react";
import { Marker, Popup } from "react-leaflet";


export interface DraggableMarkerProps {
    position: LatLng | LatLngLiteral;
    onChangePosition?: (position: LatLng | LatLngLiteral) => void;
    popup?: ReactNode;
}

export function DraggableMarker({ position, onChangePosition, popup }: DraggableMarkerProps) {
    const markerRef = useRef<L.Marker>(null);

    const handleDragEnd = useCallback(() => {
        const marker = markerRef.current;
        if (marker != null) {
            const newPosition = marker.getLatLng();
            onChangePosition?.(newPosition);
        }
    }, [onChangePosition]);

    return (
        <Marker
            draggable
            eventHandlers={{ dragend: handleDragEnd }}
            position={position}
            ref={markerRef}
        >
            <Popup minWidth={90}>
                {popup}
            </Popup>
        </Marker>
    );
}
