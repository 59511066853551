import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//  SLICE's
import authSlice from 'services/slices/authSlice';

//  API's
import { adminAPI } from './api/admin';
import { assistantAPI } from './api/assistant';

//  API's EXTERNAL
import nominatimApi from "services/api/nominatimApi";
import googleGeocodingApi from "services/api/googleGeocodingApi";


// https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data


const rootReducer = combineReducers({
    //  SLICE's
    [authSlice.name]: authSlice.reducer,
    //  API's
    [adminAPI.reducerPath]: adminAPI.reducer,

    //  API's ASSISTANT
    [assistantAPI.reducerPath]: assistantAPI.reducer,

    //  API's EXTERNAL
    [nominatimApi.reducerPath]: nominatimApi.reducer,
    [googleGeocodingApi.reducerPath]: googleGeocodingApi.reducer,
});


const persistedReducer = persistReducer({
    key: 'root',
    version: 1,
    storage,
}, rootReducer);


export const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    reducer: persistedReducer,
    middleware(getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(adminAPI.middleware)
            .concat(assistantAPI.middleware)
            .concat(nominatimApi.middleware)
            .concat(googleGeocodingApi.middleware)
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;