import { Lock, LockOpen } from "@mui/icons-material";
import { Autocomplete, debounce, IconButton, InputAdornment, TextField } from "@mui/material";
import { LatLngBounds } from "leaflet";
import { useEffect, useMemo, useRef, useState } from "react";
import { Geocoding, GeocodingItem, useLazyGeocodingQuery } from "services/api/googleGeocodingApi";

interface AutocompleteGeocodingProps {
    bounds?: LatLngBounds | null;
    geocoding?: GeocodingItem | null;
    onChangeGeocoding?: (value: GeocodingItem | null) => void;
    isLock?: boolean;
    onChangeLock?: (isLock: boolean) => void;
    components?: Geocoding.Components;
}

export function AutocompleteGeocoding({
    bounds,
    isLock,
    onChangeLock,
    geocoding,
    onChangeGeocoding,
    components,
}: AutocompleteGeocodingProps) {
    const [inputValue, setInputValue] = useState<string>("");

    const [execQuery, { data, isLoading }] = useLazyGeocodingQuery();
    const options = useMemo(() => {
        return data?.results || [];
    }, [data?.results]);

    const debounceFetch = debounce((value: string) => {
        execQuery({
            address: value,
            component: components,
            bounds: bounds ?? undefined,
            language: 'es-419',
            region: 'co',
        });
    }, 1000);

    useEffect(() => {
        if (inputValue.length < 4)
            return;

        debounceFetch(inputValue);
        // Limpiar el debounce en el desmontaje
        return () => {
            debounceFetch.clear();
        };
    }, [inputValue, debounceFetch]); // Solo depende de inputValue

    return (
        <Autocomplete<GeocodingItem>
            disablePortal
            fullWidth
            size="small"
            readOnly={isLock}
            loading={isLoading}
            options={options}
            filterOptions={(options) => options}
            value={geocoding}
            inputValue={inputValue}
            getOptionLabel={(value) => value.formatted_address}
            getOptionKey={(value) => value.place_id}
            onInputChange={(_, value) => setInputValue(value)}
            onChange={(_, value) => onChangeGeocoding?.(value)}
            renderInput={(params) => (<TextField
                label="Dirección"
                name="address"
                {...params}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                        <>
                            {params.InputProps.startAdornment}
                            <InputAdornment position="start">
                                <IconButton onClick={() => onChangeLock?.(!isLock)}>
                                    {isLock ? <Lock /> : <LockOpen />}
                                </IconButton>
                            </InputAdornment>
                        </>
                    ),
                }}
            />)}
        />
    );
}
