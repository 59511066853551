import { useEffect, useMemo } from "react";
import { Box, Paper, Stack, Toolbar, Typography } from "@mui/material";
import { useNotification } from "contexts/NotificationContext";
import { MessageBubble } from "components/Message/MessageBubble";
import { Markdown } from "components/Markdown/Markdown";
import { MapPreview } from "components/Maps/MapPreview";
import { LatLng } from "leaflet";
import { AvatarWithBadge } from "components/Avatar/AvatarWithBadge";
import { Computer, Engineering, InsertDriveFileRounded, Person, Settings } from "@mui/icons-material";
import { Conversation } from "types/Conversation";
import { useLazyRetrieveConversationMessagesQuery } from "services/api/assistant";


interface MessagesProps {
    conversation?: Conversation;
}


export function Messages(props: MessagesProps) {
    const conversation = useMemo(() => props.conversation, [props.conversation]);

    const [trigger, { data, isError, error }] = useLazyRetrieveConversationMessagesQuery();

    const notify = useNotification();

    useEffect(() => {
        if (isError)
            notify.http(error);
    }, [error, isError, notify]);


    useEffect(() => {
        if (conversation)
            trigger(conversation?.id);
    }, [trigger, conversation]);

    const RenderRoleTitle = ({ message: { role } }: any) => {
        const getTitle = () => {
            switch (role) {
                case "system":
                    return "Sistema";
                case "user":
                    return "Usuario";
                case "assistant":
                    return "Asistente virtual";
                case "agent":
                    return "Agente";
                default:
                    return role;
            }
        }
        return (<>{getTitle()}</>)
    };

    const renderContentUser = ({ content, location, media }: Conversation.Message) => {
        const hasContent = !!content;
        const hasLocation = location && (location.latitude !== 0 || location.longitude !== 0);
        const hasMedia = media && (Array.isArray(media) && media.length > 0);

        if (!hasContent && !hasLocation && !hasMedia) {
            return <Typography variant="overline">No content</Typography>;
        }

        let newContent = "";
        const renderLocation = (location: Conversation.Message.Location) => {
            if (location.label)
                newContent += `### ${location.label}\n\n`;
            if (location.address)
                newContent += location.address.split(",").map((it) => `- ${it}`).join('\n');
            return (<MapPreview
                title={location.address || location.label || "Ubicación"}
                location={new LatLng(location.latitude, location.longitude)} />)
        };

        if (hasMedia) {
            newContent += media.map((it, index) => {
                if (it.content_type.startsWith("image"))
                    return `${index + 1}. ![Image](${it.media_url})`
                if (it.content_type.startsWith("audio"))
                    return `${index + 1}. [Audio](${it.media_url})`;
                if (it.content_type.startsWith("video"))
                    return `${index + 1}. [Video](${it.media_url})`;
                return `${index + 1}. [Attachment](${it.media_url})`;
            }).join('\n');
        }

        if (hasContent) {
            if (hasMedia)
                newContent += `\n`;
            try {
                let json = JSON.parse(content);
                newContent += "```JSON\n"
                newContent += JSON.stringify(json, null, 2);
                newContent += "\n```"
            } catch (e) {
                newContent += content;
            }
        }

        return (
            <>
                {location && renderLocation(location)}
                <Markdown>{newContent}</Markdown>
            </>
        )
    };


    const MessageContent = ({ message }: any) => {
        if (["system", "assistant", "agent"].includes(message.role))
            return (message.content ?
                <Markdown>{message.content}</Markdown> :
                <Typography variant="overline">No content</Typography>
            );

        if (message.role === "user")
            return renderContentUser(message);

        return <span>no content for role {message.role}</span>;
    }

    const RenderAvatar = ({ message }: any) => {
        return (
            <AvatarWithBadge Avatar={{
                alt: message.role,
                children: (
                    message.role === "system" ? <Computer /> :
                        (message.role === "user" ? <Person /> :
                            (message.role === "assistant" ? <Engineering /> :
                                (message.role === "agent" ? <Settings /> : <InsertDriveFileRounded />)
                            )
                        ))
            }} />
        );
    }

    return (
        <Paper sx={{ height: "82vh", backgroundColor: 'background.default' }}>
            <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, backgroundColor: 'background.paper' }}>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="contentTitle"
                    component="div">
                    {"Mensajes"}
                </Typography>
            </Toolbar>
            <Box sx={{ height: "70vh", p: 4, overflow: "auto" }}>
                <Stack spacing={2} direction="column">
                    {
                        data?.map((it) => (<MessageBubble<Conversation.Message>
                            sender="user"
                            message={it}
                            TitleComponent={RenderRoleTitle}
                            ContentComponent={MessageContent}
                            AvatarComponent={RenderAvatar}
                        />))
                    }
                </Stack>
            </Box>
        </Paper>
    )
}
