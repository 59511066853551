import { Box, Typography } from "@mui/material";
import { InfoModal } from "components/Modal";
import { useEffect, useState } from "react";
import { SubCategory } from "types/Category";

interface ShowSubCategoryProps {
    item: SubCategory;
    onClose: () => void;
}

function Item(props: { title: string, value?: string | number | boolean }) {
    return (
        <Box flexGrow={1} overflow={"hidden"} textOverflow={"ellipsis"}>
            <Typography variant="caption" fontSize={16} fontWeight="700" color="text.primary">{props.title}: </Typography>
            <Typography variant="caption" fontSize={16} fontWeight="700" color="text.secondary">{props.value}</Typography>
        </Box>
    )
}

export function ShowSubCategory(props: ShowSubCategoryProps) {
    const [open, setOpen] = useState<boolean>(true);

    useEffect(() => {
        if (!open) {
            props.onClose()
        }
    }, [open, props])

    const onClose = () => {
        setOpen(false)
    }

    return (
        <InfoModal
            title="Detalle de la sub-categoria?"
            open={open}
            onClose={onClose}>
            <Item title="Código" value={props.item.code} />
            <Item title="Nombre" value={props.item.name} />
            {props.item.description &&
                <Item title="Descripcion" value={props.item.description} />
            }
            {props.item.categoryCode &&
                <Item title="Tipo" value={props.item.categoryCode} />
            }
        </InfoModal>
    )
}