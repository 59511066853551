import { Municipality, Settlement } from "types/Location";
import { AdminAPI } from ".";

export const injectCity = (api: AdminAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        retrieveCities: build.query<Municipality[], void>({
            query: () => ({
                url: "/cities",
                method: "GET"
            }),
            providesTags: (result) => ([
                { type: "City", id: `LIST` },
                ...(result || []).map(({ code: id }) => ({ type: "City", id } as const))
            ]),
        }),
        retrieveTownsInCity: build.query<Settlement[], string | null>({
            query: (code) => ({
                url: `/cities/${code}/settlements`,
                method: "GET"
            }),
            providesTags: (result, err, code) => ([
                { type: "City", id: `Settlement_${code}` },
                ...(result || []).map(({ code: id }) => ({ type: "Settlement", id } as const))
            ]),
        }),
    }),
    overrideExisting: "throw",
});