import { ArrowDownward, ArrowUpward, Computer, Delete, Edit, Engineering, Info, Person, Settings } from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { AvatarWithBadge } from "components/Avatar/AvatarWithBadge";
import moment from "moment";
import { Fragment, MouseEvent, useMemo, useState } from "react";
import { DeleteMessage } from "./delete/DeleteMessage";
import { ContentSystem } from "./content/ContentSystem";
import { ContentUser } from "./content/ContentUser";
import { ContentAssitant } from "./content/ContentAssitant";
import { ContentTool } from "./content/ContentTool";
import { UpdateSystem } from "./update/UpdateSystem";
import { UpdateUser } from "./update/UpdateUser";
import { UpdateAssistant } from "./update/assistant/UpdateAssistant";
import { TrainMessage } from "types/Train";
import { AssistantMessage, Message, SystemMessage, ToolMessage, UserMessage } from "types/Message";
import { UpdateTool } from "./update/UpdateTool";


interface BubbleProps {
    message: TrainMessage;
    sender: TrainMessage['role'];
    onMove?: ({ id }: TrainMessage, direction: "up" | "down") => void
}

export function Bubble(props: BubbleProps) {
    const source = useMemo(() => props.message, [props.message]);
    const handleMove = useMemo(() => props.onMove, [props.onMove]);

    const message: Message = useMemo(() => {
        switch (source.role) {
            case "system":
                return {
                    role: "system",
                    name: source.name,
                    content: source.content,
                } as SystemMessage;
            case "user":
                return {
                    role: "user",
                    name: source.name,
                    content: source.content,
                } as UserMessage;
            case "assistant":
                return {
                    role: "assistant",
                    name: source.name,
                    content: source.content,
                    toolCalls: source.toolCalls,
                } as AssistantMessage;
            case "tool":
                return {
                    role: "tool",
                    toolCallId: source.toolCallId,
                    content: source.content,
                } as ToolMessage;
        }
    }, [source]);


    const isSent = useMemo(() => source.role === props.sender, [source.role, props.sender]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [updating, handleUpdate] = useState<boolean>(false);
    const [deleting, handleDelete] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const AvatarIcon = useMemo(() => {
        switch (source.role) {
            case "system":
                return Computer;
            case "user":
                return Person;
            case "assistant":
                return Engineering;
            case "tool":
                return Settings;
        }
    }, [source.role])

    const title = useMemo(() => {
        switch (source.role) {
            case "system":
                return "Sistema";
            case "user":
                return "Usuario";
            case "assistant":
                return "Asistente virtual";
            case "tool":
                return "Herramienta";
        }
    }, [source.role]);

    const content = useMemo(() => {
        switch (message?.role) {
            case "system":
                return <ContentSystem message={message} />
            case "user":
                return <ContentUser message={message} />
            case "assistant":
                return <ContentAssitant message={message} />
            case "tool":
                return <ContentTool message={message} />
            default:
                return (<>No content</>);
        }
    }, [message])

    return (
        <Fragment>
            <Stack
                key={source.id}
                direction="row"
                spacing={1}
                sx={{ my: 1 }}
                flexDirection={isSent ? "row-reverse" : "row"}>

                {!isSent && <AvatarWithBadge Avatar={{ alt: source.role, children: <AvatarIcon /> }} />}

                <Box
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    sx={{ maxWidth: '65%', minWidth: 'auto' }}>
                    <Typography variant="caption">
                        {title}
                    </Typography>
                    <Box sx={{ position: 'relative' }}>
                        <Paper sx={{
                            p: 1.25,
                            borderRadius: '8px',
                            borderTopRightRadius: isSent ? 0 : '8px',
                            borderTopLeftRadius: isSent ? '8px' : 0,
                            backgroundColor: isSent ? 'primary.main' : 'background.paper',
                            color: isSent ? 'primary.contrastText' : 'text.primary',
                        }}>
                            <Stack spacing={.5}>
                                {content}
                                <Typography
                                    variant="caption"
                                    sx={{ textAlign: 'right', fontWeight: 300, fontSize: '.7rem' }}
                                >
                                    {moment(source.createdAt).format('hh:mm a')}
                                </Typography>
                            </Stack>
                        </Paper>
                        {isHovered &&
                            <Box sx={{
                                flexDirection: isSent ? "row-reverse" : "row",
                                position: 'absolute',
                                top: 20,
                                ...(isSent
                                    ? {
                                        left: 0,
                                        transform: 'translate(-100%, -50%)',
                                    }
                                    : {
                                        right: 0,
                                        transform: 'translate(100%, -50%)',
                                    }),
                            }}>
                                <IconButton
                                    edge={isSent ? "start" : "end"}
                                    aria-label="info"
                                    color="info"
                                    onClick={(event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}>
                                    <Info />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={isHovered && anchorEl !== null}
                                    onClose={() => setAnchorEl(null)}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    {handleMove &&
                                        <Fragment>
                                            <MenuItem onClick={() => handleMove(source, "up")}>
                                                <ListItemIcon>
                                                    <ArrowUpward fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Subir</ListItemText>
                                            </MenuItem>
                                            <MenuItem onClick={() => handleMove(source, "down")}>
                                                <ListItemIcon>
                                                    <ArrowDownward fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Bajar</ListItemText>
                                            </MenuItem>
                                        </Fragment>
                                    }
                                    <MenuItem onClick={() => handleUpdate(true)}>
                                        <ListItemIcon>
                                            <Edit fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Editar</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleDelete(true)}>
                                        <ListItemIcon>
                                            <Delete fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Eliminar</ListItemText>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        }
                    </Box>
                </Box>
            </Stack>



            {
                updating && message.role === "system" &&
                <UpdateSystem
                    open={updating}
                    onClose={() => handleUpdate(false)}
                    message={{ ...message, id: source.id, chat_id: source.chatId }} />
            }

            {
                updating && message.role === "user" &&
                <UpdateUser
                    open={updating}
                    onClose={() => handleUpdate(false)}
                    message={{ ...message, id: source.id, chat_id: source.chatId }} />
            }

            {
                updating && message.role === "assistant" &&
                <UpdateAssistant
                    open={updating}
                    onClose={() => handleUpdate(false)}
                    message={{ ...message, id: source.id, chat_id: source.chatId }} />
            }

            {
                updating && message.role === "tool" &&
                <UpdateTool
                    open={updating}
                    onClose={() => handleUpdate(false)}
                    message={{ ...message, id: source.id, chat_id: source.chatId }} />
            }

            {
                deleting && <DeleteMessage
                    open={deleting}
                    onClose={() => handleDelete(false)}
                    message={source} />
            }

        </Fragment >
    )
}