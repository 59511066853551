import { Add } from "@mui/icons-material";
import { Box, Chip, List, Paper, Toolbar, Typography } from "@mui/material";
import { MouseEvent, useEffect, useMemo, useState } from "react";
import { MenuCreateMessage } from "./create/MenuCreateMessage";
import { Bubble } from "./Bubble";
import { TrainChat, TrainMessage } from "types/Train";
import { useLazyRetrieveTrainMessagesQuery, useMoveTrainMessageMutation } from "services/api/assistant";
import { Role } from "types/Message";

interface MessagesProps {
    chat?: TrainChat;
    sender?: Role;
}

export function Messages(props: MessagesProps) {
    const chatSid = useMemo(() => props.chat?.id, [props.chat]);
    const sender = useMemo(() => props.sender || "user", [props.sender]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [messages, setMessages] = useState<TrainMessage[]>();

    const [execMove] = useMoveTrainMessageMutation();
    const [trigger, { data, }] = useLazyRetrieveTrainMessagesQuery();

    useEffect(() => {
        setMessages(data);
    }, [data]);

    useEffect(() => {
        if (chatSid)
            trigger(chatSid)
        setMessages(undefined);
    }, [trigger, chatSid]);

    const handleMoveMessage = async ({ id: sid }: TrainMessage, direction: 'up' | 'down') => {
        if (!messages || !chatSid)
            return;

        const index = messages.findIndex((it) => (it.id === sid));
        if (index === -1)
            return;

        await execMove({ id: sid, chatId: chatSid, direction })
    }

    return (
        <Paper>
            <Toolbar
                id="messages-toolbar"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 0,
                    bgcolor: 'background.paper'
                }}
            >
                <Typography variant="h6" component="div">
                    Mensajes
                </Typography>
                <Chip
                    icon={<Add />}
                    label={"NUEVO"}
                    color="primary"
                    sx={{ mr: 1, ml: 1 }}
                    onClick={(event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
                    disabled={!chatSid}
                />
            </Toolbar>

            <MenuCreateMessage
                anchorEl={anchorEl}
                open={!!anchorEl}
                chatId={chatSid}
                onClose={() => setAnchorEl(null)}
            />

            <Box sx={{
                height: "72vh",
                overflow: "auto",
                px: 2,
                bgcolor: 'background.default',
            }}>
                <List
                    dense
                    disablePadding
                    component="nav"
                    aria-labelledby="messages-toolbar"
                >
                    {messages?.map((it) => (
                        <Bubble
                            sender={sender}
                            key={it.id}
                            message={it as any}
                            onMove={handleMoveMessage}
                        />
                    ))}
                </List>
            </Box>
        </Paper>
    )
}