import { TrainChat, TrainMessage } from "../../../types/Train";
import { AssistantAPI } from ".";

export const injectTrainChat = (api: AssistantAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        createTrainChat: build.mutation<TrainChat, TrainChat.Create>({
            query: (body) => ({
                url: "/trains/chats",
                method: "POST",
                data: body
            }),
            invalidatesTags: (result, err, { caseId: case_id, completionId: completion_id }) => {
                if (!!completion_id) {
                    return [
                        { type: "TrainCase", id: `TrainChat_${case_id}` },
                        { type: "Completion", id: completion_id },
                        { type: "Completion", id: `LIST` }
                    ];
                }
                return [{ type: "TrainCase", id: `TrainChat_${case_id}` }];
            }
        }),
        retrieveTrainChat: build.query<TrainChat, TrainChat['id']>({
            query: (id: TrainChat['id']) => ({
                url: `/trains/chats/${id}`,
                method: "GET"
            }),
            providesTags: (result, err, id) => ([{ type: "TrainChat", id }]),
        }),
        updateTrainChat: build.mutation<{ affectedCount: number }, TrainChat.Update>({
            query: (body) => {
                const { id, caseId: case_id, ...data } = body;
                return ({
                    url: `/trains/chats/${id}`,
                    method: "PUT",
                    data
                })
            },
            invalidatesTags: (result, error, { id, caseId: case_id }) => [
                { type: "TrainChat", id },
                { type: "TrainCase", id: `TrainChat_${case_id}` },
            ],
        }),
        updateTrainChatState: build.mutation<{ affectedCount: number }, TrainChat.UpdateState>({
            query: (body) => {
                const { id, state } = body;
                return ({
                    url: `/trains/chats/${id}/state`,
                    method: "PUT",
                    data: { state }
                })
            },
            invalidatesTags: (result, error, { id, caseId: case_id }) => [
                { type: "TrainChat", id },
                { type: "TrainCase", id: `TrainChat_${case_id}` },
            ],
        }),
        deleteTrainChat: build.mutation<{ affectedCount: number }, TrainChat.Delete>({
            query: ({ id }) => ({
                url: `/trains/chats/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, { id, caseId: case_id }) => [
                { type: "TrainChat", id },
                { type: "TrainCase", id: `TrainChat_${case_id}` },
            ],
        }),
        retrieveTrainMessages: build.query<TrainMessage[], TrainMessage['id']>({
            query: (id: TrainMessage['id']) => ({
                url: `/trains/chats/${id}/messages`,
                method: "GET"
            }),
            providesTags: (result, err, id) => ([
                { type: "TrainChat", id: `TrainMessage_${id}` },
                ...(result || []).map(({ id }) => ({ type: "TrainMessage", id } as const))
            ]),
        }),
    }),
    overrideExisting: "throw",
});
