import { Box, TextField } from "@mui/material";
import { FormModal } from "components/Modal";
import { useMemo, useState } from "react";
import { CreateAssistantTools } from "./CreateAssistantTools";
import { useNotification } from "contexts/NotificationContext";
import { useCreateTrainMessageMutation } from "services/api/assistant";
import { AssistantMessage } from "types/Message";


interface CreateAssistantProps {
    open: boolean;
    onClose: () => void;
    chatId: number;
}

export function CreateAssistant(props: CreateAssistantProps) {
    const open = useMemo(() => props.open, [props.open]);
    const handleClose = useMemo(() => props.onClose, [props.onClose]);
    const chatId = useMemo(() => props.chatId, [props.chatId]);

    const [execCreate] = useCreateTrainMessageMutation();
    const notify = useNotification();
    const [data, setData] = useState<Partial<AssistantMessage>>({});


    const handleSubmit = async (_: { [key: string]: FormDataEntryValue; }) => {
        try {
            const hasContent = !!data.content;
            const hasTools = !!data.toolCalls && data.toolCalls.length > 0;

            if (!hasContent && !hasTools)
                throw new Error("El contenido es requerido!");

            const message: AssistantMessage = {
                role: "assistant",
                name: data.name,
                content: data.content,
                toolCalls: hasTools ? data.toolCalls : undefined,
            }

            await execCreate({ ...message, chatId: chatId });
            return true;
        } catch (error) {
            notify.http(error);
            return false;
        }
    }

    return (
        <FormModal
            title="Nuevo mensaje del asistente"
            buttonPositive="Guardar"
            dialogProps={{ maxWidth: "xs", fullWidth: true }}
            onClose={handleClose}
            onSubmit={handleSubmit}
            open={open}>
            <Box>
                <TextField
                    type="text"
                    name="name"
                    label="Nombre (opcional)"
                    value={data?.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                />
                <TextField
                    type="text"
                    name="content"
                    label="Contenido"
                    value={data?.content}
                    onChange={(e) => setData({ ...data, content: e.target.value })}
                    variant="outlined"
                    margin="dense"
                    minRows={3}
                    maxRows={5}
                    size="small"
                    multiline
                    fullWidth
                />

                <CreateAssistantTools
                    onChange={(tools) => setData((prev) => ({ ...prev, toolCalls: tools }))} />

            </Box>
        </FormModal >
    )
}