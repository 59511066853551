import { LatLng } from "leaflet";
import { LayersControl, MapContainer, TileLayer, WMSTileLayer } from "react-leaflet";
import { useGeocodingQuery, useReverseGeocodingQuery } from "services/api/googleGeocodingApi";


//  https://augmented-ui.com/
//  https://arwes.dev/

//  https://commerce.nearform.com/blog/2021/future-ui/
//  https://i.pinimg.com/originals/2b/3a/96/2b3a96825f17276b7550c869da0b3fd3.gif
export function HomePage() {

    //const { data, error } = useGeocodingQuery({ address: "Carrera 11 sur 74 45", language: "es-419", region: "co" });
    // const { data, error } = useReverseGeocodingQuery({
    //     latlng: new LatLng(10.9264389, -74.8195789),
    //     language: "es-419",
    //     region: "co",
    //     resultType: ['street_address']
    // });
    // if (error) {
    //     console.error('useGeocodingQuery', { error });
    // } else {
    //     console.log('useGeocodingQuery', data);
    // }

    return (
        <MapContainer
            style={{ height: '88vh', width: '100%' }}
            center={[10.976, -74.806]}
            zoom={12}
            dragging={true}
            doubleClickZoom={true}
            scrollWheelZoom={true}
            zoomControl={true}
        >
            <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="Google Maps">
                    <TileLayer
                        maxZoom={20}
                        subdomains={"0123"}
                        url="https://mt{s}.google.com/vt/lyrs=m@186112443&hl=x-local&src=web&x={x}&y={y}&z={z}&s=Galile&apistyle=s.e:l.i|p.v:off,s.t:3|s.e:g"
                        attribution={`&copy; <a href="https://www.google.com/intl/en_US/help/terms_maps.html">Google</a>`}
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Open Street Map">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                </LayersControl.BaseLayer>

                <LayersControl.Overlay checked name="Eventos calor">
                    <WMSTileLayer
                        url='https://maps.tured123.co/ogc/tured123'
                        layers="hot_events"
                        format="image/png"
                        transparent={true}
                        version="1.3.0"
                        attribution="&copy;2024 TuRed123"
                    />
                </LayersControl.Overlay>

                <LayersControl.Overlay name="Eventos puntos">
                    <WMSTileLayer
                        url='https://maps.tured123.co/ogc/tured123'
                        layers="events"
                        format="image/png"
                        transparent={true}
                        version="1.3.0"
                        attribution="&copy; TuRed123"
                    />
                </LayersControl.Overlay>

            </LayersControl>
        </MapContainer>
    )
}