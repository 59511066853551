import { Autocomplete, Box, createFilterOptions, debounce, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLazyFindUserQuery } from "services/api/admin";
import { User } from "types/User";


interface AutocompleteDocumentProps {
    user?: User | null;
    onChangeUser?: (value: User | null) => void;
    documentType?: User.DocumentType | null;
}

const filter = createFilterOptions<User>();


export function AutocompleteDocument({
    user, onChangeUser,
    documentType,
}: AutocompleteDocumentProps) {
    const [inputValue, setInputValue] = useState<string>("");

    const [execQuery, { data, isLoading }] = useLazyFindUserQuery();
    const options = useMemo(() => (data || []), [data]);

    const debounceFetch = useRef(
        debounce((document: string, documentType?: User.DocumentType | null) => {
            execQuery({ document, documentType, });
        }, 1000)
    ).current;

    useEffect(() => {
        if (inputValue.length < 4)
            return;

        debounceFetch(inputValue, documentType);
        // Limpiar el debounce en el desmontaje
        return () => {
            debounceFetch.clear();
        };
    }, [inputValue, debounceFetch, documentType]); // Solo depende de inputValue

    return (
        <Autocomplete<User>
            disablePortal
            fullWidth
            size="small"
            loading={isLoading}
            options={options}
            filterOptions={(options, state) => {
                const filtered = filter(options, state);
                const { inputValue } = state;
                const isExisting = options.some((option) => inputValue === option.nip);
                if (inputValue !== '' && inputValue.length > 3 && !isExisting) {
                    filtered.push({
                        id: 0,
                        nip: inputValue,
                        nipType: documentType ?? "NUIP",
                        firstName: "",
                        secondName: null,
                        surname: "",
                        secondSurname: null,
                        email: "",
                        phone: "",
                        address: "",
                        fullName: "Nuevo usuario",
                    });
                }

                return filtered;
            }}
            value={user}
            inputValue={inputValue}
            getOptionLabel={(value) => value.nip}
            getOptionKey={(value) => value.id}
            onInputChange={(_, value) => setInputValue(value)}
            onChange={(_, value) => onChangeUser?.(value)}
            renderInput={(params) => (<TextField
                name="document_number"
                label="Número documento"
                variant="outlined"
                required
                {...params} />)}
            renderOption={(props, value) => {
                return (
                    <Box component="li" {...props} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="subtitle2">{value.fullName}</Typography>
                        <Typography variant="body2">{value.nipType} {Number(value.nip).toLocaleString('es-ES')}</Typography>
                    </Box>);
            }}
        />
    );
}
