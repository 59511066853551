import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Event } from "types/Event";


function Item(props: { title: string, value?: string }) {
    return (
        <Box flexGrow={1} overflow={"hidden"} textOverflow={"ellipsis"}>
            <Typography variant="caption" color="primary" fontWeight="700">{props.title}: </Typography>
            <Typography variant="caption" color="text.primary">{props.value}</Typography>
        </Box>
    )
}


interface Props {
    event?: Event;
}


export function HistoryTab(props: Props) {
    const [event, setEvent] = useState<Event>();
    useEffect(() => setEvent(props.event), [props.event]);

    return (
        <Box sx={{ p: 2 }}>
            <Stack direction="column" spacing={2}>
                <Stack direction="column">
                    <Item title="Identificación" value={"`${event?.user.nipType}${event?.user.nip} `"} />
                    <Item title="Nombres" value={"`${event?.user.firstName} ${event?.user.secondName || ''}`"} />
                    <Item title="Apellidos" value={"`${event?.user.surname} ${event?.user.secondSurname || ''}`"} />
                    <Item title="Telefono" value={"event?.user.phone"} />
                    <Item title="Correo" value={"event?.user.email"} />
                    <Item title="Dirección" value={"event?.user.address"} />
                </Stack>
            </Stack>
        </Box>
    )
}