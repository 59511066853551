import { Content } from "features/Content/Content";
import { CellProps, EnhancedTable } from "features/EnhancedTable/EnhancedTable";
import { useState } from "react";
import { Chip, Tooltip, Typography, } from "@mui/material";
import { Settlement } from "types/Location";
import { useRetrieveTownsQuery } from "services/api/admin";




export function SettlementsPage() {
    const [hasCreate, setCreate] = useState<boolean>(false);
    const { data, isLoading, isFetching } = useRetrieveTownsQuery();

    const columns: CellProps<Settlement>[] = [
        { label: 'Código', key: 'code' },
        { label: 'Nombre', key: 'name' },
        { label: 'Municipio', key: 'municipalityName' },
        {
            label: 'Tipo', key: 'type',
            renderCell(value, element) {
                return (<Tooltip title={(value === "CM") ? "Cabera municipal" : (value === "CP") ? "Centro poblado" : (value === "ANM") ? "Area no municipalizada" : ""} >
                    <Typography>{value}</Typography>
                </Tooltip>)
            },
        }
    ];

    return (
        <Content
            title='Municipios'
            onCreateClick={() => setCreate(true)}
            boxComponent={{ overflow: "auto", height: "68vh" }}>
            <EnhancedTable<Settlement>
                size={(data || []).length}
                columns={columns}
                data={data || []}
                onPageChange={() => ({})}
                isLoading={isLoading}
                isFetching={isFetching}
            />
        </Content>
    )
}