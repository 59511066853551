import { Box, TextField } from "@mui/material";
import { FormModal } from "components/Modal";
import { useNotification } from "contexts/NotificationContext";
import { useMemo, useState } from "react";
import { useCreateTrainCaseMutation, } from "services/api/assistant";
import { TrainCase, } from "types/Train";


interface CaseCreateProps {
    open: boolean;
    onClose: () => void;
    subTypeId: number;
}

export function CaseCreate(props: CaseCreateProps) {
    const open = useMemo(() => props.open, [props.open]);
    const subTypeId = useMemo(() => props.subTypeId, [props.subTypeId]);

    const handleClose = useMemo(() => props.onClose, [props.onClose]);
    const [execCreate] = useCreateTrainCaseMutation();

    const notify = useNotification();
    const [data, setData] = useState<Partial<TrainCase.Create>>({});

    const handleSubmit = async (_: { [key: string]: FormDataEntryValue; }) => {
        try {
            if (!data.name)
                throw new Error("El nombre es requerido!");

            const body: TrainCase.Create = {
                subTypeId: subTypeId,
                name: data.name,
                description: data.description
            }

            await execCreate(body).unwrap();
            notify.success("Caso creado");
            return true;
        } catch (error) {
            notify.http(error);
            return false;
        }
    }

    return (
        <FormModal
            title="Nuevo caso"
            buttonPositive="Guardar"
            dialogProps={{ maxWidth: "xs", fullWidth: true }}
            onClose={handleClose}
            onSubmit={handleSubmit}
            open={open}>
            <Box>
                <TextField
                    type="text"
                    name="name"
                    label="Nombre"
                    value={data?.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    inputProps={{ maxLength: 32 }}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    required
                />
                <TextField
                    type="text"
                    name="content"
                    label="Descripción (opcional)"
                    value={data?.description}
                    onChange={(e) => setData({ ...data, description: e.target.value })}
                    inputProps={{ maxLength: 128 }}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    minRows={3}
                    maxRows={5}
                    multiline
                    fullWidth
                />
            </Box>
        </FormModal >
    )
}