import { Content } from "features/Content/Content";
import { CellProps, EnhancedTable } from "features/EnhancedTable/EnhancedTable";
import { useState } from "react";
import { NewSubCategory } from "./NewSubCategory";
import { Chip, } from "@mui/material";
import { MenuAction } from "./MenuAction";
import { SubCategory } from "types/Category";
import { useRetrieveSubCategoriesQuery } from "services/api/admin";


interface SubCategoriesPageProps {

}


export function SubCategoriesPage(props: SubCategoriesPageProps) {
    const [hasCreate, setCreate] = useState<boolean>(false);
    const { data, isLoading, isFetching } = useRetrieveSubCategoriesQuery();


    const columns: CellProps<SubCategory>[] = [
        { label: 'Código', key: 'code' },
        { label: 'Nombre', key: 'name' },
        { label: 'Descripción', key: 'description' },
        { label: 'Categoria', key: 'categoryName', },
        {
            label: 'Acciones', renderCell(_, item) {
                return (<MenuAction item={item} />)
            },
        },
    ];

    return (
        <Content
            title='Categorias'
            onCreateClick={() => setCreate(true)}
            boxComponent={{ overflow: "auto", height: "68vh" }}>
            <EnhancedTable<SubCategory>
                size={(data || []).length}
                columns={columns}
                data={data || []}
                onPageChange={() => ({})}
                isLoading={isLoading}
                isFetching={isFetching}
            />

            {hasCreate && <NewSubCategory onClose={() => setCreate(false)} />}
        </Content>
    )
}