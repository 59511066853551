import { Content } from "features/Content/Content";
import { CellProps, EnhancedTable } from "features/EnhancedTable/EnhancedTable";
import { useState } from "react";
import { Chip, } from "@mui/material";
import { useRetrieveCitiesQuery } from "services/api/admin";
import { Municipality } from "types/Location";


export function CitiesPage() {
    const [hasCreate, setCreate] = useState<boolean>(false);
    const { data, isLoading, isFetching } = useRetrieveCitiesQuery();


    const columns: CellProps<Municipality>[] = [
        { label: 'Id', key: 'code' },
        { label: 'Código', key: 'code' },
        { label: 'Nombre', key: 'name' },
        { label: 'Departamento', key: 'departmentName' }
    ];

    return (
        <Content
            title='Municipios'
            onCreateClick={() => setCreate(true)}
            boxComponent={{ overflow: "auto", height: "68vh" }}>
            <EnhancedTable<Municipality>
                size={(data || []).length}
                columns={columns}
                data={data || []}
                onPageChange={() => ({})}
                isLoading={isLoading}
                isFetching={isFetching}
            />
        </Content>
    )
}