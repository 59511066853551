import { ReactNode, useEffect, useMemo, useState } from "react";
import { Box, Button, Collapse, IconButton, IconButtonProps, Paper, Stack, styled, Toolbar, Typography } from "@mui/material";
import { useNotification } from "contexts/NotificationContext";
import { MessageBubble } from "components/Message/MessageBubble";
import { Markdown } from "components/Markdown/Markdown";
import { Computer, Engineering, InsertDriveFileRounded, Person, Settings } from "@mui/icons-material";
import { AvatarWithBadge } from "components/Avatar/AvatarWithBadge";
import { Completion } from "types/Completion";
import { useLazyRetrieveCompletionMessagesQuery } from "services/api/assistant";
import { AssistantMessage, ToolMessage } from "types/Message";


interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

interface MessagesProps {
    completion?: Completion;
}


export function Messages(props: MessagesProps) {
    const completion = useMemo(() => props.completion, [props.completion]);

    const [trigger, { data, isError, error }] = useLazyRetrieveCompletionMessagesQuery();

    const notify = useNotification();

    useEffect(() => {
        if (isError)
            notify.http(error);
    }, [error, isError, notify]);

    useEffect(() => {
        if (completion)
            trigger(completion?.id);
    }, [trigger, completion]);


    const RenderRoleTitle = ({ message: { role } }: any) => {
        const getTitle = () => {
            switch (role) {
                case "system":
                    return "Sistema";
                case "user":
                    return "Usuario";
                case "assistant":
                    return "Asistente virtual";
                case "tool":
                    return "Herramienta";
                default:
                    return role;
            }
        }

        return (<>{getTitle()}</>)
    };

    const ContentAssistant = ({ content, toolCalls: tool_calls }: AssistantMessage) => {
        const hasContent = !!content;
        const hasToolCall = !!tool_calls && (Array.isArray(tool_calls) && tool_calls.length > 0);

        let tools: ReactNode[] | undefined = undefined;
        if (hasToolCall) {
            tools = tool_calls.map(({ id, type, function: { name, arguments: args } }) => {
                let data;
                if (typeof args === "object") {
                    data = "```JSON\n";
                    data += JSON.stringify(args, null, 2);
                    data += "\n```";
                } else if (typeof args === "string") {
                    try {
                        let json = JSON.parse(args);
                        data = "```JSON\n";
                        data += JSON.stringify(json, null, 2);
                        data += "\n```";
                    } catch (error) {
                        data = args;
                    }
                } else data = args;

                return (
                    <Paper
                        variant="outlined"
                        sx={{
                            px: 1.75,
                            py: 1.25,
                            borderRadius: '16px',
                        }}
                    >
                        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>{`${type} ${name}()`}</Typography>
                        <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>{`${id}`}</Typography>
                        <Markdown>{data}</Markdown>
                    </Paper>
                )
            })
        }

        let newContent = "";
        if (hasContent) {
            try {
                let json = JSON.parse(content);
                newContent += "```JSON\n";
                newContent += JSON.stringify(json, null, 2);
                newContent += "\n```";
            } catch (error) {
                newContent = content;
            }
        }

        return (
            <>
                {hasContent && <Markdown>{newContent}</Markdown>}
                {hasToolCall && tools}
            </>
        );
    };

    const ContentTool = ({ content, toolCallId: tool_call_id }: ToolMessage) => {
        const [expanded, setExpanded] = useState(false);

        let data;
        if (typeof content === "object") {
            data = "```JSON\n";
            data += JSON.stringify(content, null, 2);
            data += "\n```";
        } else if (typeof content === "string") {
            try {
                let json = JSON.parse(content);
                data = "```JSON\n";
                data += JSON.stringify(json, null, 2);
                data += "\n```";
            } catch (error) {
                data = content;
            }
        } else data = content;

        const handleToggle = () => {
            setExpanded(!expanded);
        };

        return (
            <>
                <Typography variant="subtitle2">{`${tool_call_id}`}</Typography>
                <Button onClick={handleToggle}>
                    {expanded ? 'Collapse' : 'Expand'}
                </Button>
                <Collapse in={expanded}>
                    <Markdown>{data}</Markdown>
                </Collapse>
            </>
        );
    }

    const MessageContent = ({ message }: any) => {
        if (["system", "user"].includes(message.role)) {
            if (typeof message.content === "object") {
                let data = "```JSON\n";
                data += JSON.stringify(message.content, null, 2);
                data += "\n```";
                return <Markdown>{data}</Markdown>
            } else if (typeof message.content === "string") {
                let data;
                try {
                    let json = JSON.parse(message.content);
                    data = "```JSON\n";
                    data += JSON.stringify(json, null, 2);
                    data += "\n```";
                } catch (error) {
                    data = message.content;
                }
                return <Markdown>{data}</Markdown>
            }

            return <Typography variant="overline">No content</Typography>;
        }

        if (message.role === "assistant")
            return ContentAssistant(message);

        if (message.role === "tool")
            return ContentTool(message);

        return <span>no content for role {message.role}</span>;
    }

    const RenderAvatar = ({ message }: any) => {
        return (
            <AvatarWithBadge Avatar={{
                alt: message.role,
                children: (
                    message.role === "system" ? <Computer /> :
                        (message.role === "user" ? <Person /> :
                            (message.role === "assistant" ? <Engineering /> :
                                (message.role === "tool" ? <Settings /> : <InsertDriveFileRounded />)
                            )
                        ))
            }} />
        );
    }

    return (
        <Paper sx={{ height: "82vh", backgroundColor: 'background.default' }}>
            <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, backgroundColor: 'background.paper' }}>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="contentTitle"
                    component="div">
                    {"Mensajes"}
                </Typography>
            </Toolbar>
            <Box sx={{ height: "70vh", p: 4, overflow: "auto" }}>
                <Stack spacing={2} direction="column">
                    {
                        data?.map((it) => (<MessageBubble<Completion.Message>
                            sender="user"
                            message={it}
                            TitleComponent={RenderRoleTitle}
                            ContentComponent={MessageContent}
                            AvatarComponent={RenderAvatar}
                        />))
                    }
                </Stack>
            </Box>
        </Paper>
    )
}
