import { FollowUp } from "types/Event";
import { AdminAPI } from ".";

export const injectFollowUp = (api: AdminAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        createFollowUp: build.mutation<FollowUp, FollowUp.Create>({
            query: ({ eventId: event_id, description }) => ({
                url: `/events/${event_id}/follow_ups`,
                method: "POST",
                data: { description }
            }),
            invalidatesTags: [{ type: "FollowUp", id: "LIST" }]
        }),
        retrieveFollowUp: build.query<FollowUp, number>({
            query: (id) => ({
                url: `/follow_ups/${id}`,
                method: "GET"
            }),
            providesTags: (result, err, id) => ([{ type: "FollowUp", id }]),
        }),
        updateFollowUp: build.mutation<FollowUp, FollowUp.Update>({
            query: (body) => {
                const { id, ...data } = body
                return ({
                    url: `/follow_ups/${id}`,
                    method: "PUT",
                    data
                })
            },
            invalidatesTags: (result, error, { id }) => [{ type: "FollowUp", id: "LIST" }, { type: "FollowUp", id }],
        }),
    }),
    overrideExisting: "throw",
});