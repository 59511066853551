import { Stack } from "@mui/material";
import { useEffect, useRef, } from "react";
import { LatLng, LatLngBounds, LatLngLiteral, Map } from "leaflet";
import { MapContainer, TileLayer } from "react-leaflet";
import { MinimapControl } from "./MinimapControl";
import { DraggableMarker } from "./DraggableMarker";
import { AutocompleteLazyQuery } from "components/Autocomplete/AutocompleteLazyQuery";
import { Department, Municipality, Settlement } from "types/Location";
import { useLazyRetrieveCitiesInDepartmantQuery, useLazyRetrieveDepartmentsQuery, useLazyRetrieveTownsInCityQuery, useRetrieveCountryQuery } from "services/api/admin";


interface MapAddressPickerProps {
    bounds?: LatLngBounds | null;
    onChangeBounds?: (value: LatLngBounds | null) => void;
    department?: Department | null;
    onChangeDepartment?: (value: Department | null) => void;
    municipality?: Municipality | null;
    onChangeMunicipality?: (value: Municipality | null) => void;
    settlement?: Settlement | null;
    onChangeSettlement?: (value: Settlement | null) => void;
    position?: LatLng | LatLngLiteral | null;
    onChangePosition?: (value: LatLng | LatLngLiteral | null) => void;
}

export function MapAddressPicker({
    bounds, onChangeBounds,
    department, onChangeDepartment,
    municipality, onChangeMunicipality,
    settlement, onChangeSettlement,
    position, onChangePosition,
}: MapAddressPickerProps) {
    const { data: country } = useRetrieveCountryQuery();

    const mapRef = useRef<Map | null>(null); // Referencia al mapa de Leaflet

    useEffect(() => {
        if (settlement && settlement.bounds) {
            const newBounds = new LatLngBounds(settlement.bounds.southWest, settlement.bounds.northEast);
            onChangeBounds?.(newBounds);
        } else if (municipality && municipality.bounds) {
            const newBounds = new LatLngBounds(municipality.bounds.southWest, municipality.bounds.northEast);
            onChangeBounds?.(newBounds);
        } else if (department && department.bounds) {
            const newBounds = new LatLngBounds(department.bounds.southWest, department.bounds.northEast);
            onChangeBounds?.(newBounds);
        } else if (country && country.bounds) {
            const countryBounds = new LatLngBounds(country.bounds.southWest, country.bounds.northEast);
            onChangeBounds?.(countryBounds); // Volver a los bounds del país
        } else {
            onChangeBounds?.(null);
        }
    }, [country, department, municipality, onChangeBounds, settlement]);

    useEffect(() => {
        if (bounds && mapRef.current) {
            const map = mapRef.current;
            map.fitBounds(bounds);
        }
    }, [bounds]);

    return (
        <Stack direction="column" spacing={4}>
            {(country && bounds) && (
                <MapContainer
                    ref={mapRef}
                    style={{ height: 256, width: '100%' }}
                    dragging={true}
                    doubleClickZoom={true}
                    scrollWheelZoom={true}
                    zoomControl={true}
                    bounds={bounds}
                >
                    <MinimapControl
                        position="topright"
                        zoom={10}
                        tileLayer={{
                            url: "https://mt{s}.google.com/vt/lyrs=m@186112443&hl=x-local&src=web&x={x}&y={y}&z={z}&s=Galile&apistyle=s.e:l.i|p.v:off,s.t:3|s.e:g",
                            subdomains: "0123"
                        }}
                    />
                    <TileLayer
                        maxZoom={20}
                        url="https://mt{s}.google.com/vt/lyrs=m@186112443&hl=x-local&src=web&x={x}&y={y}&z={z}&s=Galile&apistyle=s.e:l.i|p.v:off,s.t:3|s.e:g"
                        subdomains={"0123"}
                        attribution={`&copy; <a href="https://www.google.com/intl/en_US/help/terms_maps.html">Google</a>`}
                    />
                    {position && <DraggableMarker position={position} onChangePosition={onChangePosition} />}
                </MapContainer>
            )}

            {country && (
                <AutocompleteLazyQuery<Department, void>
                    query={useLazyRetrieveDepartmentsQuery}
                    queryArg={undefined}
                    valueField="code"
                    displayField="name"
                    TextField={{ label: "Departamento", name: "department", required: true }}
                    onChangeValue={onChangeDepartment}
                />
            )}

            {department && (
                <AutocompleteLazyQuery<Municipality, Municipality['code'] | null>
                    query={useLazyRetrieveCitiesInDepartmantQuery}
                    queryArg={department.code}
                    valueField="code"
                    displayField="name"
                    TextField={{ label: "Ciudad", name: "municipality", required: true }}
                    onChangeValue={onChangeMunicipality}
                />
            )}

            {(municipality && municipality.size && municipality.size > 1) && (
                <AutocompleteLazyQuery<Settlement, Settlement['code'] | null>
                    query={useLazyRetrieveTownsInCityQuery}
                    queryArg={municipality.code}
                    valueField="code"
                    displayField="name"
                    TextField={{ label: "Centro poblado", name: "settlement", required: true }}
                    onChangeValue={onChangeSettlement}
                />
            )}
        </Stack>
    );
}
