import { Action } from "@reduxjs/toolkit"
import { createApi } from "@reduxjs/toolkit/query/react"
import { REHYDRATE } from "redux-persist"
import { RootState } from "services/store"
import { axiosBaseQuery } from "services/utils/axiosBaseQuery"
import { injectAuth } from "./injectAuth"
import { injectAccount } from "./injectAccount"
import { injectUser } from "./injectUser"
import { injectCategory } from "./injectCategory"
import { injectSettlement } from "./injectSettlement"
import { injectFollowUp } from "./injectFollowUp"
import { injectType } from "./injectType"
import { injectDepartment } from "./injectDepartment"
import { injectSubCategory } from "./injectSubCategory"
import { injectCity } from "./injectCity"
import { injectEvent } from "./injectEvent"
import { injectCountry } from "./injectCountry"


export const adminAPI = createApi({
    reducerPath: 'admin',
    tagTypes: [
        'AUTH', 'Account', 'User',
        'Category', 'SubCategory', 'Type',
        'Country', 'Department', 'City', 'Settlement',
        'Event', 'FollowUp',
    ],
    baseQuery: axiosBaseQuery({ baseURL: process.env.REACT_APP_API_URL }),
    refetchOnMountOrArgChange: 10,
    refetchOnReconnect: true,
    extractRehydrationInfo: (action, { reducerPath, }): any => {
        const isHydrateAction = (action: Action):
            action is Action<typeof REHYDRATE> & { key: string; payload: RootState; err: unknown; } =>
            action.type === REHYDRATE;
        if (isHydrateAction(action)) {
            // when persisting the api reducer
            if (action.key === 'key used with redux-persist') {
                return action.payload
            }

            // When persisting the root reducer
            if (action.payload)
                return action.payload[reducerPath]
        }
        return undefined
    },
    endpoints: () => ({}), // Sin endpoints inicialmente
});

export type AdminAPI = typeof adminAPI;

//  AUTH's
export const {
    useAuthMutation
} = injectAuth(adminAPI);

//  USER's
export const {
    useMeQuery,

    useLazyMeQuery,
} = injectAccount(adminAPI);

//  USER's
export const {
    useRetrieveUserQuery,
    useRetrieveUserEventsQuery,
    useFindUserQuery,

    useLazyRetrieveUserQuery,
    useLazyRetrieveUserEventsQuery,
    useLazyFindUserQuery,
} = injectUser(adminAPI);

//  TYPE's
export const {
    useRetrieveCategoriesQuery,
    useCreateCategoryMutation,
    useRetrieveCategoryQuery,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation,
    useRetrieveSubCategoriesInCategoryQuery,

    useLazyRetrieveCategoriesQuery,
    useLazyRetrieveCategoryQuery,
    useLazyRetrieveSubCategoriesInCategoryQuery,
} = injectCategory(adminAPI);

export const {
    useRetrieveSubCategoriesQuery,
    useCreateSubCategoryMutation,
    useRetrieveSubCategoryQuery,
    useUpdateSubCategoryMutation,
    useDeleteSubCategoryMutation,
    useRetrieveTypesInSubCategoryQuery,

    useLazyRetrieveSubCategoriesQuery,
    useLazyRetrieveSubCategoryQuery,
    useLazyRetrieveTypesInSubCategoryQuery,
} = injectSubCategory(adminAPI);

export const {
    useRetrieveTypesQuery,
    useCreateTypeMutation,
    useRetrieveTypeQuery,
    useUpdateTypeMutation,
    useDeleteTypeMutation,

    useLazyRetrieveTypesQuery,
    useLazyRetrieveTypeQuery,
} = injectType(adminAPI);

//  LOACTION's
export const {
    useRetrieveCountryQuery,

    useLazyRetrieveCountryQuery,
} = injectCountry(adminAPI);

export const {
    useRetrieveDepartmentsQuery,
    useRetrieveCitiesInDepartmantQuery,

    useLazyRetrieveDepartmentsQuery,
    useLazyRetrieveCitiesInDepartmantQuery,
} = injectDepartment(adminAPI);

export const {
    useRetrieveCitiesQuery,
    useRetrieveTownsInCityQuery,

    useLazyRetrieveCitiesQuery,
    useLazyRetrieveTownsInCityQuery,
} = injectCity(adminAPI);

export const {
    useRetrieveTownsQuery,

    useLazyRetrieveTownsQuery,
} = injectSettlement(adminAPI);

// INCIDENT's
export const {
    useRetrieveEventsQuery,
    useCreateEventMutation,
    useRetrieveEventQuery,
    useRetrieveFollowUpsEventQuery,

    useLazyRetrieveEventsQuery,
    useLazyRetrieveEventQuery,
    useLazyRetrieveFollowUpsEventQuery,
} = injectEvent(adminAPI);

export const {
    useCreateFollowUpMutation,
    useRetrieveFollowUpQuery,
    useUpdateFollowUpMutation,

    useLazyRetrieveFollowUpQuery,
} = injectFollowUp(adminAPI);
