import { Country } from "types/Location";
import { AdminAPI } from ".";

export const injectCountry = (api: AdminAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        retrieveCountry: build.query<Country, void>({
            query: () => ({
                url: "/countries/170",
                method: "GET"
            }),
            providesTags: (result) => ([{ type: "Country", id: `170` } as const]),
        }),
    }),
    overrideExisting: "throw",
});