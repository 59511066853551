import { Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from "@mui/material";
import { FormModal } from "components/Modal";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { LatLng, LatLngBounds, LatLngLiteral, } from "leaflet";
import { AutocompleteLazyQuery } from "components/Autocomplete/AutocompleteLazyQuery";
import { Event } from "types/Event";
import { useCreateEventMutation, useLazyRetrieveTypesQuery } from "services/api/admin";
import { Municipality, Department, Settlement } from "types/Location";
import { Type } from "types/Category";
import { MapAddressPicker } from "components/Maps/MapAddressPicker";
import { AutocompleteGeocoding } from "components/Autocomplete/AutocompleteGeocoding";
import { Geocoding, GeocodingItem, useLazyReverseGeocodingQuery } from "services/api/googleGeocodingApi";
import { AutocompleteDocument } from "components/Autocomplete/AutocompleteDocument";
import { User } from "types/User";

interface NewIncidentProps {
    onClose: () => void;
    onCreated?: (item: Event['id']) => void;
}

declare type FormData = { [key: string]: FormDataEntryValue; };

export function NewEvent(props: NewIncidentProps) {
    const [execCreate, { isSuccess }] = useCreateEventMutation();
    const [execQuery,] = useLazyReverseGeocodingQuery();

    const [open, setOpen] = useState<boolean>(true);
    const [bounds, setBounds] = useState<LatLngBounds | null>(null);
    const [department, setDepartment] = useState<Department | null>(null);
    const [municipality, setMunicipality] = useState<Municipality | null>(null);
    const [settlement, setSettlement] = useState<Settlement | null>(null);

    const [position, setPosition] = useState<LatLng | LatLngLiteral | null>(null);
    const [geocoding, setGeocoding] = useState<GeocodingItem | null>(null);
    const [isAddresLock, setAddressLock] = useState<boolean>(false);
    const [geocodingComponents, setGeocodingComponents] = useState<Geocoding.Components>({ country: 'CO' });

    const [type, setType] = useState<Type | null>(null);

    const [documentType, setDocumentType] = useState<User.DocumentType | null>(null);
    const [user, setUser] = useState<User | null>(null);

    const [firstName, setFirstName] = useState<string>("");
    const [secondName, setSecondName] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [secondLastname, setSecondLastname] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [email, setEmail] = useState<string>("");


    useEffect(() => {
        if (!position) return;
        if (isAddresLock) return;

        const isLatLngInstance = position instanceof LatLng;
        const maxMargin = 8.9E-5;

        if (geocoding && geocoding.geometry) {
            const location = geocoding.geometry.location;
            if (isLatLngInstance && (position as LatLng).equals(location, maxMargin)) {
                return;
            }

            const tmpPosition = new LatLng(position.lat, position.lng);
            if (tmpPosition.equals(location, maxMargin)) {
                return;
            }
        }

        execQuery({
            latlng: position,
            region: 'co',
            language: 'es-419',
            resultType: ['street_address'],
        }).unwrap().then((result) => {
            if (!result || !result.results) return;
            const [item] = result.results;
            if (!item) return;
            setGeocoding(item);
        }).catch((error) => {
            console.error('Error al ejecutar la consulta de geocodificación:', error);
        });
    }, [execQuery, geocoding, isAddresLock, position]);

    useEffect(() => {
        if (!geocoding) {
            setPosition(null);
            return;
        };

        setPosition(geocoding.geometry.location);

        if (geocoding.geometry.viewport) {
            const bounds = new LatLngBounds(geocoding.geometry.viewport.southwest, geocoding.geometry.viewport.northeast);
            setBounds(bounds);
        }
    }, [geocoding]);


    useEffect(() => {
        setGeocodingComponents((it) => {
            let administrativeArea: string | undefined;
            let locality: string | undefined;

            if (settlement && settlement.name) {
                locality = settlement.name;
                if (municipality && municipality.name) {
                    administrativeArea = municipality.name;
                } else if (department && department.name) {
                    administrativeArea = department.name;
                }
            } else if (municipality && municipality.name) {
                locality = municipality.name;
                if (department && department.name) {
                    administrativeArea = department.name;
                }
            } else if (department && department.name) {
                administrativeArea = department.name;
            }

            return {
                ...it,
                administrativeArea,
                locality,
            };
        });
    }, [department, municipality, settlement]);

    useEffect(() => {
        if (!user || user.id <= 0) return;
        if (documentType) return;
        setDocumentType(user.nipType);
    }, [documentType, user]);

    useEffect(() => {
        if (!user) return;
        setFirstName(user.firstName);
        setSecondName(user.secondName ?? "");
        setLastname(user.surname);
        setSecondLastname(user.secondSurname ?? "");
    }, [user]);

    //
    const handleClose = () => setOpen(false);

    const getFormStringValue = (data: FormData, key: string): string | undefined => {
        const value = data[key];
        if (typeof value === "string") {
            return value;
        }
    }

    const handleSubmit = async (formData: FormData) => {
        if (!type) {
            return false;
        }
        if (!municipality) {
            return false;
        }
        if (!geocoding) {
            return false;
        }

        const data: Event.Create = {
            typeCode: type?.code,
            municipalityCode: municipality?.code,
            description: getFormStringValue(formData, "description")!!,
            referencePlace: getFormStringValue(formData, "placeReference")!!,
            address: geocoding?.formatted_address,
            point: geocoding.geometry.location,
        };

        if (user) {
            if (user.id > 0) {
                data.userId = user.id;
            } else {
                data.user = {
                    nip: user.nip,
                    nipType: getFormStringValue(formData, "document_type")!!,
                    firstName: getFormStringValue(formData, "first_name")!!,
                    secondName: getFormStringValue(formData, "second_name")!!,
                    surname: getFormStringValue(formData, "lastname")!!,
                    secondSurname: getFormStringValue(formData, "second_lastname")!!,
                    email: getFormStringValue(formData, "email")!!,
                    phone: getFormStringValue(formData, "phone")!!,
                    address: getFormStringValue(formData, "address")!!,
                };
            }
        }


        await execCreate(data);
        if (isSuccess && props.onCreated) {
            props.onCreated(-1);
        }
        return true;
    }


    return (
        <FormModal
            title="Nuevo evento"
            buttonPositive="Crear"
            open={open}
            onClose={handleClose}
            onSubmit={handleSubmit}
            dialogProps={{ fullWidth: true, maxWidth: "lg" }}
        >
            <Grid container spacing={4} rowSpacing={1} width={'100%'}>
                <Grid xs={4}>
                    <Divider textAlign="left">Ubicación geografica</Divider>
                    <MapAddressPicker
                        bounds={bounds}
                        onChangeBounds={setBounds}
                        department={department}
                        onChangeDepartment={setDepartment}
                        municipality={municipality}
                        onChangeMunicipality={setMunicipality}
                        settlement={settlement}
                        onChangeSettlement={setSettlement}
                        position={position}
                        onChangePosition={setPosition}
                    />
                </Grid>
                <Grid xs={4}>
                    <Stack direction="column" spacing={4}>
                        <Divider textAlign="left">Datos del evento</Divider>
                        <AutocompleteGeocoding
                            bounds={bounds}
                            isLock={isAddresLock}
                            onChangeLock={setAddressLock}
                            geocoding={geocoding}
                            onChangeGeocoding={setGeocoding}
                            components={geocodingComponents}
                        />
                        <TextField
                            margin="dense"
                            name="placeReference"
                            label="Lugar referencia"
                            type="text"
                            fullWidth
                            variant="outlined"
                            size="small"
                        />
                        <AutocompleteLazyQuery<Type, void>
                            query={useLazyRetrieveTypesQuery}
                            queryArg={undefined}
                            valueField="id"
                            displayField="name"
                            onChangeValue={setType}
                            TextField={{ label: "Tipo de evento", name: "event", required: true }}
                        />
                        <TextField
                            required
                            margin="dense"
                            name="description"
                            label="Descripción de lo sucedido"
                            type="text"
                            fullWidth
                            multiline
                            minRows={6}
                            maxRows={6}
                            variant="outlined"
                            size="small"
                        />
                    </Stack>
                </Grid>
                <Grid xs={4}>
                    <Stack direction="column" spacing={4}>
                        <Divider textAlign="left">Datos del reportante</Divider>
                        <Stack direction="row" spacing={4}>
                            <FormControl fullWidth size="small" required={!(!!user && user.id > 0)}>
                                <InputLabel>Tipo documento</InputLabel>
                                <Select<User.DocumentType>
                                    label="Tipo documento"
                                    name="document_type"
                                    value={documentType ?? ""}
                                    onChange={(event: SelectChangeEvent<User.DocumentType>) => setDocumentType(event.target.value as User.DocumentType)}
                                    readOnly={!!user && user.id > 0}
                                >
                                    <MenuItem value="NUIP">NUIP</MenuItem>
                                    <MenuItem value="RC">Registro civil</MenuItem>
                                    <MenuItem value="TI">Tarjeta de identidad</MenuItem>
                                    <MenuItem value="CC">Cédula de ciudadanía</MenuItem>
                                    <MenuItem value="TE">Tarjeta de extranjería</MenuItem>
                                    <MenuItem value="CE">Cédula de extranjería</MenuItem>
                                    <MenuItem value="PP">Pasaporte</MenuItem>
                                    <MenuItem value="PEP">Permiso especial de permanencia</MenuItem>
                                    <MenuItem value="DIE">Documento de identificación extranjero</MenuItem>
                                </Select>
                            </FormControl>
                            <AutocompleteDocument
                                user={user}
                                onChangeUser={setUser}
                                documentType={documentType}
                            />
                        </Stack>
                        <Stack direction="row" spacing={4}>
                            <TextField
                                margin="dense"
                                name="first_name"
                                label="Primer nombre"
                                type="text"
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={firstName}
                                onChange={(event) => setFirstName(event.target.value)}
                                InputProps={{ readOnly: !!user && user.id > 0 }}
                                required={!(!!user && user.id > 0)}
                            />
                            <TextField
                                margin="dense"
                                name="second_name"
                                label="Segundo nombre"
                                type="text"
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={secondName}
                                onChange={(event) => setSecondName(event.target.value)}
                                InputProps={{ readOnly: !!user && user.id > 0 }}
                            />
                        </Stack>
                        <Stack direction="row" spacing={4}>
                            <TextField
                                margin="dense"
                                name="lastname"
                                label="Primer apellido"
                                type="text"
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={lastname}
                                onChange={(event) => setLastname(event.target.value)}
                                InputProps={{ readOnly: !!user && user.id > 0 }}
                                required={!(!!user && user.id > 0)}
                            />
                            <TextField
                                name="second_lastname"
                                label="Segundo apellido"
                                type="text"
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={secondLastname}
                                onChange={(event) => setSecondLastname(event.target.value)}
                                InputProps={{ readOnly: !!user && user.id > 0 }}
                            />
                        </Stack>

                        {!(!!user && user.id > 0) && <>
                            <Divider textAlign="left">Datos de contacto</Divider>
                            <TextField
                                name="phone"
                                label="Telefono"
                                type="text"
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={phone}
                                onChange={(event) => setPhone(event.target.value)}
                            />
                            <TextField
                                name="email"
                                label="Correo"
                                type="text"
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </>
                        }
                    </Stack>
                </Grid>
            </Grid>
        </FormModal >
    )
}