import { Box, Chip, Stack, Typography } from "@mui/material";
import { MapMarker } from "components/Maps/MapMarker";
import { LatLng } from "leaflet";
import { useEffect, useState } from "react";
import { Event } from "types/Event";


function Item(props: { title: string, value?: string }) {
    return (
        <Box flexGrow={1} overflow={"hidden"} textOverflow={"ellipsis"}>
            <Typography variant="caption" color="primary" fontWeight="700">{props.title}: </Typography>
            <Typography variant="caption" color="text.primary">{props.value}</Typography>
        </Box>
    )
}


interface Props {
    event?: Event;
}


export function InfoTab(props: Props) {
    const [event, setEvent] = useState<any>();
    const [position, setLatLng] = useState<LatLng | null>(null);

    useEffect(() => setEvent(props.event), [props.event]);
    useEffect(() => {
        if (event) {
            const location = new LatLng(event.location.latitude, event.location.longitude);
            setLatLng(location);
        } else {
            setLatLng(null);
        }
    }, [event]);

    return (
        <Box sx={{ p: 2 }}>
            <Stack direction="column" spacing={2}>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                    }}>
                    <Box sx={{ color: 'text.secondary' }}>{event?.id} • {event?.type.name}</Box>
                    <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
                        {event?.type.categoryName}
                    </Box>
                    {/* Nivel de confianza
                    <Box
                        sx={{
                            color: 'success.dark',
                            display: 'inline',
                            fontWeight: 'bold',
                            mx: 0.5,
                            fontSize: 14,
                        }}
                    >
                        70%
                    </Box>
                    <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 14 }}>
                        confianza
                    </Box>
                    */}
                </Box>
                <Stack direction="column">
                    <Item title="Reportante" value={`${event?.user.firstName} ${event?.user.secondName || ''} ${event?.user.surname} ${event?.user.secondSurname || ''}`} />
                    <Item title="Creado por" value={(event?.account.type === 'admin' ? event?.account.name : (event?.account.type === 'whatsapp' ? 'Whatsapp' : 'Fuente desconocida'))} />
                    <Item title="Municipio" value={`${event?.municipality.code} • ${event?.municipality.name}`} />
                    <Item title="Dirección" value={event?.address} />
                    <Item title="Referencia" value={event?.referencePlace} />

                    <Item title="Descripción" value={event?.description} />
                </Stack>
                <Chip label="MAPA" color="primary" />
                {position && <MapMarker position={position} title={event ? `${event?.address}\n${event?.referencePlace}` : undefined} />}
            </Stack>
        </Box>
    )
}