import { Avatar, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { ButtonLink } from "components/Button/ButtonLink";
import { RelativeTime } from "components/RelativeTime/RelativeTime";
import { Content } from "features/Content/Content";
import { CellProps, EnhancedTable } from "features/EnhancedTable/EnhancedTable";
import { NewEvent } from "./NewEvent";
import { ReactNode, useState } from "react";
import { Event } from "types/Event";
import { useRetrieveEventsQuery } from "services/api/admin";
import { SvgLoader } from "components/SVG/SvgLoader";
import { red, yellow, green, grey } from "@mui/material/colors";
import { AdminPanelSettings, AdminPanelSettingsOutlined, AdminPanelSettingsTwoTone, Api, WhatsApp } from "@mui/icons-material";


interface EventsPageProps {

}

export function EventsPage(props: EventsPageProps) {
    const [hasCreate, setCreate] = useState<boolean>(false);
    const { data } = useRetrieveEventsQuery();

    const columns: CellProps<any>[] = [
        { label: 'COD', key: 'id', sx: { maxWidth: '4vw' } },
        {
            label: 'VÍA', sx: { maxWidth: '4vw' }, renderCell(value, element) {
                switch (element.origin) {
                    case "whatsapp":
                        return <Tooltip title="WhatsApp" arrow><WhatsApp /></Tooltip>;
                    case "admin":
                        return <Tooltip title="TuRed123" arrow><AdminPanelSettingsOutlined /></Tooltip>;
                    default:
                        return <Avatar sx={{ width: 24, height: 24, bgcolor: grey[500] }} children="?" />;
                }
            },
        },

        {
            label: 'NIVEL', key: 'status', sx: { maxWidth: '6vw' }, renderCell(value, element) {
                if (!element) return null;
                switch (element?.type.priority) {
                    case "ALTA":
                        return <Chip
                            label={"ALTO"}
                            color="error"
                            size="small"
                            variant="outlined"
                        />;
                    case "MEDIA":
                        return <Chip
                            label={"MEDIO"}
                            color="warning"
                            size="small"
                            variant="outlined"
                        />;
                    case "BAJA":
                        return <Chip
                            label={"BAJO"}
                            color="success"
                            size="small"
                            variant="outlined"
                        />;
                    default:
                        return <Chip
                            label={"DESCONOCIDO"}
                            color={"default"}
                            size="small"
                            variant="outlined"
                        />;
                }
            }
        },
        // {
        //     label: 'NIVEL', sx: { maxWidth: '4vw' }, renderCell(value, element) {
        //         switch (element.type.priority) {
        //             case "ALTA":
        //                 return <Tooltip title={`Prioridad Alta`} arrow><Avatar sx={{ width: 24, height: 24, bgcolor: red[500] }} children=" " /></Tooltip>;
        //             case "MEDIA":
        //                 return <Tooltip title={`Prioridad Media`} arrow><Avatar sx={{ width: 24, height: 24, bgcolor: yellow[500] }} children=" " /></Tooltip>;
        //             case "BAJA":
        //                 return <Tooltip title={`Prioridad Baja`} arrow><Avatar sx={{ width: 24, height: 24, bgcolor: green[500] }} children=" " /></Tooltip>;
        //             default:
        //                 return <Tooltip title={`Prioridad Desconocida`} arrow><Avatar sx={{ width: 24, height: 24, bgcolor: grey[500] }} children="?" /></Tooltip>;
        //         }
        //     },
        // },
        {
            label: 'TIPO', key: 'icon', sx: { maxWidth: '5vw' }, renderCell(value, element) {
                return (
                    <Tooltip title={element.type.categoryName} arrow>
                        <span>
                            <SvgLoader svgName={element.type.icon as string} />
                        </span>
                    </Tooltip>
                )
            },
        },
        {
            label: 'SUB-TIPO', key: 'description', renderCell(value, element) {
                return (
                    <Tooltip title={element.type.subCategoryName} arrow>
                        <Typography variant="body2" noWrap textOverflow="ellipsis">
                            {element.type.subCategoryName as string}
                        </Typography>
                    </Tooltip>
                )
            },
        },
        { label: 'DESCRIPCIÓN', key: 'description', },
        { label: 'DIRECCIÓN', key: 'address', },
        {
            label: 'FECHA', key: 'createdAt', sx: { maxWidth: '10vw' }, renderCell(value) {
                return <RelativeTime date={value as string} />
            },
        },
        {
            label: 'GESTIÓN', sx: { maxWidth: '5vw' }, renderCell(value, element) {
                switch (element.type.priority) {
                    case "ALTA":
                        return <center><Tooltip title={`Prioridad Alta`} arrow><Avatar sx={{ width: 24, height: 24, bgcolor: red[500] }} children=" " /></Tooltip></center>;
                    case "MEDIA":
                        return <center><Tooltip title={`Prioridad Media`} arrow><Avatar sx={{ width: 24, height: 24, bgcolor: yellow[500] }} children=" " /></Tooltip></center>;
                    case "BAJA":
                        return <center><Tooltip title={`Prioridad Baja`} arrow><Avatar sx={{ width: 24, height: 24, bgcolor: green[500] }} children=" " /></Tooltip></center>;
                    default:
                        return <center><Tooltip title={`Prioridad Desconocida`} arrow><Avatar sx={{ width: 24, height: 24, bgcolor: grey[500] }} children="?" /></Tooltip></center>;
                }
            },
        },
        {
            label: 'ESTADO', key: 'status', renderCell(value) {
                if (!value) return null;
                return <Chip
                    label={value === "reported" ? "REPORTADO" : value}
                    color={value === "reported" ? "error" : "default"}
                    size="small"
                    variant="outlined"
                />
            }
        },
        {
            label: 'ACCIONES', key: 'id', renderCell(value) {
                return (<ButtonLink to={`${value}`} >Detalle</ButtonLink>)
            }
        },
    ];

    return (
        <Content
            title='Eventos'
            onCreateClick={() => setCreate(true)}
            boxComponent={{ overflow: "auto", height: "78vh" }}>
            <EnhancedTable<Event>
                size={(data || []).length}
                columns={columns}
                data={data || []}
                onPageChange={() => ({})} />
            {hasCreate && <NewEvent onClose={() => setCreate(false)} />}
        </Content>
    )
}