import { useEffect, useState } from "react";
import { Chip, ListItem, ListItemText, MenuItem, MenuList, Paper, Stack, Toolbar, Typography } from "@mui/material";
import moment from "moment";

import { useNotification } from "contexts/NotificationContext";
import { Conversation } from "types/Conversation";
import { useRetrieveConversationsQuery } from "services/api/assistant";
import { useSearchParams } from "react-router-dom";


interface ConversationProps {
    onSelected?: (item?: Conversation) => void;
}

function RenderStatus({ status }: { status: Conversation['status'] }) {
    switch (status) {
        case "active":
            return <Chip label="ACTIVA" color="success" size="small" />;
        case "inactive":
            return <Chip label="INACTIVA" color="warning" size="small" />;
        case "closed":
            return <Chip label="CERRADA" color="error" size="small" />;
    }
}

export function Conversations({ onSelected }: ConversationProps) {
    const [selected, setSelected] = useState<Conversation>();
    const [searchParams] = useSearchParams();
    const conversationId = searchParams.get("id"); // Obtener el ID de la URL como string

    const { data, isError, error } = useRetrieveConversationsQuery();

    const notify = useNotification();
    useEffect(() => {
        if (isError)
            notify.http(error);
    }, [error, isError, notify]);

    useEffect(() => {
        onSelected?.(selected);
    }, [onSelected, selected]);

    useEffect(() => {
        if (!data || data.length === 0) return;
        if (selected) return;
        const idNumber = conversationId ? parseInt(conversationId, 10) : null;

        const foundConversation = data.find((it) => it.id === idNumber);
        if (foundConversation) {
            setSelected(foundConversation);
        } else if (!selected) {
            setSelected(data[0]); // Si no hay ninguno seleccionado, seleccionar el primero
        }
    }, [data, selected, conversationId]);

    return (
        <Paper sx={{ height: "82vh", overflow: "auto" }}>
            <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, }}>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="contentTitle"
                    component="div">
                    {"Conversaciones"}
                </Typography>
            </Toolbar>
            <MenuList sx={{ paddingY: 0, backgroundColor: "background.paper", height: "85%" }}>
                {data?.map((it: Conversation) => (
                    <MenuItem
                        key={it.id}
                        onClick={() => setSelected(it)}
                        selected={selected && selected.id === it.id}
                    >
                        <ListItem>
                            <ListItemText
                                primary={<Typography noWrap variant="body1">{it.sender.replace(/:\+/gm, " ").toUpperCase()}</Typography>}
                                secondary={
                                    <Typography noWrap variant="body2" color="textSecondary">
                                        <Stack direction="row" spacing={2}>
                                            <RenderStatus status={it.status} />
                                            <Chip label={moment(it.createdAt).locale("es").format('DD [de] MMMM, hh:mm a')} variant="outlined" size="small" />
                                        </Stack>
                                    </Typography>}
                            />
                        </ListItem>
                    </MenuItem>
                ))}
            </MenuList>
        </Paper>
    )
}