import { Event, FollowUp } from "types/Event";
import { AdminAPI } from ".";


export const injectEvent = (api: AdminAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        retrieveEvents: build.query<Event[], void>({
            query: () => ({
                url: "/events",
                method: "GET"
            }),
            providesTags: (result) => ([
                { type: "Event", id: `LIST` },
                ...(result || []).map(({ id }) => ({ type: "Event", id } as const))
            ]),
        }),
        createEvent: build.mutation<Event, Event.Create>({
            query: (args) => {
                const data: any = {
                    type_code: args.typeCode,
                    municipality_code: args.municipalityCode,
                    description: args.description,
                    address: args.address,
                    reference_place: args.referencePlace,
                    latitude: parseFloat(args.point.lat.toFixed(8)),
                    longitude: parseFloat(args.point.lng.toFixed(8)),
                };

                if (args.userId) {
                    data.user_id = args.userId;
                } else if (args.user) {
                    data.user = {
                        nip: args.user.nip,
                        nip_type: args.user.nipType,
                        first_name: args.user.firstName,
                        second_name: args.user.secondName,
                        surname: args.user.surname,
                        second_surname: args.user.secondSurname,
                        email: args.user.email,
                        phone: args.user.phone,
                        address: args.user.address,
                    };
                }

                return ({
                    url: "/events",
                    method: "POST",
                    data
                });
            },
            invalidatesTags: [{ type: "Event", id: "LIST" }]
        }),
        retrieveEvent: build.query<Event, string | number>({
            query: (id) => ({
                url: `/events/${id}`,
                method: "GET"
            }),
            providesTags: (result, err, id) => ([{ type: "Event", id }]),
        }),
        retrieveFollowUpsEvent: build.query<FollowUp[], string | number>({
            query: (id) => ({
                url: `/events/${id}/follow_ups`,
                method: "GET"
            }),
            providesTags: (result, err, id) => ([
                { type: "Event", id: `FollowUp_${id}` },
                ...(result || []).map(({ id }) => ({ type: "FollowUp", id } as const))
            ]),
        }),
    }),
    overrideExisting: "throw",
});