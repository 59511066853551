import { Box, CircularProgress, Skeleton, Table, TableBody, TableCell, TableCellProps, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography, styled } from "@mui/material";
import { ChangeEvent, MouseEvent, ReactNode, useEffect, useRef, useState } from "react";
import { Data } from "types/data";


declare type Order = 'asc' | 'desc';

export interface CellProps<D extends Data> extends TableCellProps {
    key?: keyof D;
    label: string;
    renderCell?: (value: D[keyof D] | null, element: D) => ReactNode;
    xs?: TableCellProps['sx']
}

interface EnhancedTableHeadProps<D extends Data> {
    columns: Array<CellProps<D>>;
    order?: Order;
    orderBy?: keyof Data;
    onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof D) => void;
}

interface EnhancedTableProps<D extends Data> {
    columns: Array<CellProps<D>>;
    data: Array<D>;
    order?: Order;
    orderBy?: keyof Data;
    size: number;
    onPageChange?: (page: number, rowsPerPage: number, orderBy?: keyof D, order?: Order) => void;
    isLoading?: boolean;
    isFetching?: boolean;
}

//------------------------------------------------------------
function descendingComparator<D extends Data>(a: D, b: D, orderBy: keyof D) {
    if (b[orderBy]!! < a[orderBy]!!) {
        return -1;
    } else if (b[orderBy]!! > a[orderBy]!!) {
        return 1;
    } else return 0;
}

function getComparator<D extends Data>(order: Order, orderBy: keyof D): (a: D, b: D) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<D extends Data>(array: readonly D[], comparator: (a: D, b: D) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [D, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

//------------------------------------------------------------

function EnhancedTableHead<D extends Data>(props: EnhancedTableHeadProps<D>) {

    const createSortHandler = (property: keyof D) =>
        (event: React.MouseEvent<unknown>) => {
            if (props.onRequestSort)
                props.onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {props.columns.map(({ label, key, align, renderCell, ...columnProps }) => (
                    <TableCell
                        key={key as string}
                        sortDirection={(props.orderBy && props.orderBy === key) ? props.order : false}
                        {...columnProps}
                    >
                        {key && <TableSortLabel
                            active={props.orderBy === key}
                            direction={props.orderBy === key ? props.order : undefined}
                            onClick={createSortHandler(key)}>
                            {label}
                        </TableSortLabel>
                        }
                        {!key && label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}


function CellData<D extends Data>(props: { value: D[keyof D] }) {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
        if (ref.current) {
            setIsOverflowing(ref.current.scrollWidth > ref.current.clientWidth || ref.current.scrollHeight > ref.current.clientHeight);
        }
    }, [ref]);

    return (
        <Tooltip title={props.value} arrow disableHoverListener={!isOverflowing}>
            <Typography variant="body2" noWrap textOverflow="ellipsis" ref={ref}>{props.value}</Typography>
        </Tooltip>
    )
}

function TableSkeleton({ rowNumber, cellNumber }: { rowNumber: number, cellNumber: number }) {
    const rowItems = Array.from({ length: rowNumber }, (_, index) => index);
    const cellItems = Array.from({ length: cellNumber }, (_, index) => index);
    const generateCell = () => (<TableCell><Skeleton animation="wave" variant="text" /></TableCell>);
    const generateRow = () => (<TableRow>{cellItems.map(() => generateCell())}</TableRow>)
    return (<>{rowItems.map(() => generateRow())}</>)
}


export function EnhancedTable<D extends Data>(props: EnhancedTableProps<D>) {
    const [data, setData] = useState<Array<D>>([]);
    const [order, setOrder] = useState<Order | undefined>(props.order);
    const [orderBy, setOrderBy] = useState<keyof Data | undefined>(props.orderBy);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => setData(props.data), [props.data]);

    const handleRequestSort = (event: MouseEvent<unknown>, property: keyof D) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property as string | number | undefined);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (props.onPageChange) {
            props.onPageChange(page, rowsPerPage, orderBy, order);
        } else {
            const sorted = stableSort(props.data, getComparator(order!!, orderBy!!)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            );
            setData(sorted);
        }
    }, [page, rowsPerPage, orderBy, order, props])

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;


    return (
        <>
            <TableContainer>
                <Table aria-labelledby="tableTitle" size="small">
                    <EnhancedTableHead
                        columns={props.columns}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {
                            (props.isLoading)
                                ? <TableSkeleton rowNumber={rowsPerPage} cellNumber={props.columns.length} />
                                : (
                                    (props.isFetching)
                                        ? <TableRow>
                                            <TableCell colSpan={props.columns.length}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                                    <CircularProgress />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        : null
                                )
                        }

                        {data.length > 0 && data.slice(startIndex, endIndex).map((row) => (
                            <TableRow hover tabIndex={-1} sx={{ cursor: 'pointer', }}>
                                {props.columns.map(({ key, align, renderCell, sx }) => (
                                    <TableCell
                                        align={align}
                                        sx={{
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            maxWidth: `${Math.round(100 / (props.columns?.length || 0))}vw`,
                                            textOverflow: 'ellipsis',
                                            ...sx,
                                        }}
                                    >
                                        {(renderCell) ? renderCell((key) ? row[key] : null, row) : null}
                                        {(!renderCell && key) ? <CellData value={row[key]} /> : null}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={props.size}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}