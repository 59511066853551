import { Action } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react"
import { LatLngBounds, LatLng, LatLngLiteral, } from "leaflet";
import { REHYDRATE } from "redux-persist";
import { RootState } from "services/store";
import { axiosBaseQuery } from "services/utils/axiosBaseQuery";

/**
 * Tipos de computación extra que se pueden solicitar.
 */
export type ExtraComputation =
    | 'ADDRESS_DESCRIPTORS'   // Descriptores de dirección
    | 'BUILDING_AND_ENTRANCES'; // Edificios y entradas

/**
 * Tipos de ubicación disponibles en la geocodificación.
 */
export type LocationType =
    | "ROOFTOP"              // Ubicación precisa en el techo
    | "RANGE_INTERPOLATED"   // Ubicación interpolada
    | "GEOMETRIC_CENTER"     // Centro geométrico
    | "APPROXIMATE";         // Aproximado

/**
 * Tipos de resultados que se pueden devolver al realizar una geocodificación.
 * 
 * En Colombia, estos tipos son relevantes para la ubicación y la estructura de direcciones.
 */
export type ResultType =
    | "street_address"                // Dirección de la calle (e.g., Carrera 7 No. 25-39, Bogotá)
    | "route"                         // Ruta (e.g., Calle 100, Bogotá)
    | "intersection"                  // Intersección (e.g., Carrera 15 con Calle 80, Bogotá)
    | "political"                     // Información política (e.g., nombre de un departamento o municipio)
    | "country"                       // País (e.g., Colombia)
    | "administrative_area_level_1"   // Área administrativa de nivel 1 (e.g., Cundinamarca, Valle del Cauca)
    | "administrative_area_level_2"   // Área administrativa de nivel 2 (e.g., Bogotá D.C., Cali)
    | "administrative_area_level_3"   // Área administrativa de nivel 3 (e.g., localidad de Chapinero, en Bogotá)
    | "administrative_area_level_4"   // Área administrativa de nivel 4 (e.g., comuna 3 en Medellín)
    | "administrative_area_level_5"   // Área administrativa de nivel 5 (e.g., barrio La Candelaria, Bogotá)
    | "administrative_area_level_6"   // Área administrativa de nivel 6 (e.g., unidad de planificación local)
    | "administrative_area_level_7"   // Área administrativa de nivel 7 (e.g., división territorial a nivel local)
    | "colloquial_area"               // Área coloquial (e.g., Chapinero Alto, Bogotá)
    | "locality"                      // Localidad (e.g., Barranquilla)
    | "sublocality"                   // Sub-localidad (e.g., La Macarena, Bogotá)
    | "neighborhood"                  // Vecindario (e.g., El Poblado, Medellín)
    | "premise"                       // Local (e.g., un centro comercial específico)
    | "subpremise"                    // Sublocal (e.g., oficina 303, edificio XYZ)
    | "plus_code"                     // Código plus (e.g., 6V9R+F3 Bogotá, Colombia)
    | "postal_code"                   // Código postal (e.g., 110311 para un área específica en Bogotá)
    | "natural_feature"               // Característica natural (e.g., Parque Tayrona)
    | "airport"                       // Aeropuerto (e.g., Aeropuerto El Dorado)
    | "park"                          // Parque (e.g., Parque Simón Bolívar)
    | "point_of_interest";            // Punto de interés (e.g., Museo del Oro, Bogotá)

/**
 * Tipos de componentes de dirección que se pueden devolver en los resultados.
 * 
 * En Colombia, estos componentes reflejan la estructura típica de las direcciones.
 */
export type AddressComponentType = ResultType
    | "floor"          // Piso (e.g., Piso 3)
    | "establishment"  // Establecimiento (e.g., Restaurante La Puerta Falsa)
    | "landmark"      // Punto de referencia (e.g., Torre Colpatria)
    | "point_of_interest" // Punto de interés (e.g., Plaza de Bolívar)
    | "parking"       // Estacionamiento (e.g., Parqueadero del Centro Comercial)
    | "post_box"      // Buzón (e.g., Buzón del servicio postal)
    | "postal_town"   // Ciudad postal (e.g., Bogotá)
    | "room"          // Habitación (e.g., habitación 402)
    | "street_number"  // Número de calle (e.g., 123 de la Carrera 7)
    | "bus_station"   // Estación de autobuses (e.g., Terminal de Transporte de Bogotá)
    | "train_station" // Estación de tren (e.g., Estación de tren de Bucaramanga)
    | "transit_station"; // Estación de tránsito (e.g., Estación de TransMilenio)


/**
 * Estados posibles de la respuesta de geocodificación.
 */
export type Status =
    | "OK"                // La solicitud fue exitosa
    | "ZERO_RESULTS"      // No se encontraron resultados
    | "OVER_DAILY_LIMIT"  // Se ha superado el límite diario
    | "OVER_QUERY_LIMIT"  // Se ha superado el límite de consultas
    | "REQUEST_DENIED"    // Solicitud denegada
    | "INVALID_REQUEST"    // Solicitud no válida
    | "UNKNOWN_ERROR";    // Error desconocido

/**
 * Espacio de nombres para la geocodificación.
 */
export namespace Geocoding {

    /**
     * Componentes opcionales que se pueden incluir en la solicitud de geocodificación.
     */
    export interface Components {
        route?: string;              // Ruta
        locality?: string;           // Localidad
        administrativeArea?: string; // Área administrativa
        postalCode?: string;         // Código postal
        country?: string;            // País
    }

    /**
     * Interfaz para una solicitud de geocodificación.
     */
    export interface Request {
        address: string;                        // Dirección a geocodificar
        component?: Components;                 // Componentes opcionales
        bounds?: LatLngBounds;                 // Límites geográficos
        language?: string;                      // Idioma para los resultados
        region?: string;                        // Región para los resultados
        extraComputations?: ExtraComputation[]; // Cálculos extra opcionales
    }

}

/**
 * Espacio de nombres para la geocodificación inversa.
 */
export namespace ReverseGeocoding {

    /**
     * Interfaz para una solicitud de geocodificación inversa.
     */
    export interface Request {
        latlng: LatLng | LatLngLiteral;         // Latitud y longitud a geocodificar
        language?: string;                      // Idioma para los resultados
        region?: string;                        // Región para los resultados
        resultType?: ResultType[];             // Tipos de resultados esperados
        locationType?: LocationType[];         // Tipos de ubicación
        extraComputations?: ExtraComputation[]; // Cálculos extra opcionales
    }
}

/**
 * Interfaz para el código plus de un resultado.
 */
export interface ResultPlusCode {
    compound_code?: string; // Código compuesto
    global_code?: string;   // Código global
}

/**
 * Interfaz que representa un componente de dirección.
 */
export interface AddressComponent {
    long_name: string;               // Nombre completo
    short_name: string;              // Nombre corto
    types: AddressComponentType[];   // Tipos de componentes
}

/**
 * Interfaz que representa la geometría de un resultado.
 */
export interface Geometry {
    location: {
        lat: number;    // Latitud
        lng: number;    // Longitud
    };
    location_type: LocationType;   // Tipo de ubicación
    viewport: {
        northeast: { lat: number; lng: number; }; // Esquina noreste del viewport
        southwest: { lat: number; lng: number; }; // Esquina suroeste del viewport
    };
    bounds?: {
        northeast: { lat: number; lng: number; };  // Límites noreste
        southwest: { lat: number; lng: number; };  // Límites suroeste
    };
}

/**
 * Interfaz que representa un ítem de resultado de geocodificación.
 */
export interface GeocodingItem {
    types: ResultType[];             // Tipos de resultado
    formatted_address: string;       // Dirección formateada
    address_components: AddressComponent[]; // Componentes de dirección
    postcode_localities?: string[];  // Localidades postales
    geometry: Geometry;              // Geometría del resultado
    place_id: string;                // ID del lugar
}

/**
 * Interfaz para manejar errores en los resultados de geocodificación.
 */
export interface ResultError {
    status: Exclude<Status, "OK">;  // Estado de error
    error_message: string;           // Mensaje de error
}

/**
 * Interfaz que representa un resultado exitoso de geocodificación.
 */
export interface Result {
    status: "OK";                   // Estado de éxito
    plus_code?: ResultPlusCode;     // Código plus opcional
    results: GeocodingItem[];          // Lista de resultados
}

function convertComponentsToString(components: Geocoding.Components): string {
    const componentArray: string[] = [];
    // Añadir cada componente si está presente
    if (components.route) {
        componentArray.push(`route:${components.route}`);
    }
    if (components.locality) {
        componentArray.push(`locality:${components.locality}`);
    }
    if (components.administrativeArea) {
        componentArray.push(`administrative_area:${components.administrativeArea}`);
    }
    if (components.postalCode) {
        componentArray.push(`postal_code:${components.postalCode}`);
    }
    if (components.country) {
        componentArray.push(`country:${components.country}`);
    }

    // Unir los componentes con '|' y devolver la cadena
    return componentArray.join('|');
}

function convertBoundsToString(bounds: LatLngBounds): string {
    const southwest = bounds.getSouthWest();
    const northeast = bounds.getNorthEast();
    return `${southwest.lat},${southwest.lng}|${northeast.lat},${northeast.lng}`
}

//https://developers.google.com/maps/documentation/geocoding/overview?hl=es-419#geocoding-requests
const api = createApi({
    reducerPath: "googleGeocoding",
    tagTypes: ["GoogleGeocoding"],
    baseQuery: axiosBaseQuery({
        baseURL: "https://maps.googleapis.com/maps/api/geocode/json",
        params: { key: "AIzaSyCv3j0LHEkdxrsZNAlZrzuLkaFdRwSmMNg" },
    }),
    extractRehydrationInfo: (action, { reducerPath, }): any => {
        const isHydrateAction = (action: Action):
            action is Action<typeof REHYDRATE> & { key: string; payload: RootState; err: unknown; } =>
            action.type === REHYDRATE;
        if (isHydrateAction(action)) {
            // when persisting the api reducer
            if (action.key === 'key used with redux-persist') {
                return action.payload
            }

            // When persisting the root reducer
            if (action.payload)
                return action.payload[reducerPath]
        }
        return undefined
    },
    endpoints: (build) => ({
        geocoding: build.query<Result, Geocoding.Request>({
            query(arg) {
                const params: any = {
                    address: arg.address,
                    ...(arg.component && { components: convertComponentsToString(arg.component) }),
                    ...(arg.bounds && { bounds: convertBoundsToString(arg.bounds) }),
                    ...(arg.language && { language: arg.language }),
                    ...(arg.region && { region: arg.region }),
                    ...(arg.extraComputations && { extraComputations: arg.extraComputations.join('|') }),
                };
                return ({
                    method: "GET",
                    params
                });
            },
        }),
        reverseGeocoding: build.query<Result, ReverseGeocoding.Request>({
            query(arg) {
                const params: any = {
                    latlng: `${arg.latlng.lat},${arg.latlng.lng}`, // Coordenadas a geocodificar
                    ...(arg.language && { language: arg.language }), // Añadir idioma si está presente
                    ...(arg.region && { region: arg.region }), // Añadir región si está presente
                    ...(arg.resultType && { result_type: arg.resultType.join('|') }), // Añadir tipo de resultado si está presente
                    ...(arg.locationType && { location_type: arg.locationType.join('|') }), // Añadir tipo de ubicación si está presente
                    ...(arg.extraComputations && { extraComputations: arg.extraComputations.join('|') }), // Añadir cálculos extra
                };
                return ({
                    method: "GET",
                    params
                });
            },
        }),
    }),
});


export default api;

export const {
    useGeocodingQuery,
    useReverseGeocodingQuery,
    useLazyGeocodingQuery,
    useLazyReverseGeocodingQuery,
} = api;