import { useEffect, useState } from 'react';
import { loadSvg } from 'utils/loadSvg';

export const SvgLoader = ({ svgName }: { svgName: string }) => {
    const [svgSrc, setSvgSrc] = useState<string | null>(null);

    useEffect(() => {
        const fetchSvg = async () => {
            const svg = await loadSvg(svgName);
            setSvgSrc(svg);
        };

        fetchSvg();
    }, [svgName]);

    if (!svgSrc) {
        return <div>Loading...</div>; // Placeholder mientras se carga
    }

    return <img src={svgSrc} alt={svgName} width={32} />;
};
