import { Box, Skeleton, Stack } from "@mui/material";
import { Composer } from "./Composer";
import { useEffect, useMemo, useState } from "react";
import { Bubble } from "./Bubble";
import { Event } from "types/Event";
import { useLazyRetrieveConversationMessagesQuery } from "services/api/assistant";

interface ChatTabProps {
    event?: Event;
}

export function ChatTab(props: ChatTabProps) {
    const event = useMemo(() => props.event as any, [props.event]);

    const [trigger, { data }] = useLazyRetrieveConversationMessagesQuery()

    useEffect(() => {
        if (event?.conversationId)
            trigger(event.conversationId)
    }, [event?.conversationId, trigger])

    if (!event || !event.conversationId) {
        return (
            <Stack spacing={2} sx={{ padding: 2, height: '100%', justifyContent: 'space-between' }}>
                <Stack spacing={2} sx={{ flexGrow: 1 }}>
                    <Stack direction="row" spacing={2}>
                        <Skeleton variant="circular" width={40} height={40} animation={false} />
                        <Skeleton variant="text" width="80%" height={80} animation={false} />
                    </Stack>
                    <Stack direction="row-reverse" spacing={2}>
                        <Skeleton variant="circular" width={40} height={40} animation={false} />
                        <Skeleton variant="text" width="80%" height={60} animation={false} />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Skeleton variant="circular" width={40} height={40} animation={false} />
                        <Skeleton variant="text" width="80%" height={80} animation={false} />
                    </Stack>
                    <Stack direction="row-reverse" spacing={2}>
                        <Skeleton variant="circular" width={40} height={40} animation={false} />
                        <Skeleton variant="text" width="80%" height={40} animation={false} />
                    </Stack>
                </Stack>

                <Skeleton variant="text" height={80} animation={false} />
            </Stack>

        )
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}>
            <Box
                sx={{
                    height: { xs: "calc(100dvh - var(--Header-height))", lg: "100dvh" },
                    display: "flex",
                    flex: 1,
                    minHeight: '50dvh',
                    px: 2,
                    py: 3,
                    flexDirection: "column-reverse",
                    mb: 4
                }}>
                <Stack spacing={2} justifyContent="flex-end">
                    {
                        data?.map((item) => <Bubble content={item} variant={item.role === "user" ? "received" : "sent"} />)
                    }
                </Stack>
            </Box>
            <Composer incidentId={event?.id} />
        </Box>
    )
}