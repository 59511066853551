import { useEffect, useState } from "react";
import { useNotification } from "contexts/NotificationContext";
import { UpdateAssistantTools } from "./UpdateAssistantTools";
import { FormModal } from "components/Modal";
import { Box, TextField } from "@mui/material";
import { AssistantMessage } from "types/Message";
import { useUpdateTrainMessageMutation } from "services/api/assistant";

interface UpdateAssistantProps {
    open: boolean;
    onClose: () => void;
    message: AssistantMessage & {
        id: number;
        chat_id: number;
    };
}

export function UpdateAssistant(props: UpdateAssistantProps) {
    const { open, onClose, message } = props;

    const [execUpdate] = useUpdateTrainMessageMutation();
    const notify = useNotification();
    const [data, setData] = useState<Partial<AssistantMessage>>({});

    useEffect(() => {
        setData(message);
    }, [message]);

    const handleSubmit = async () => {
        try {
            const hasContent = !!data.content;
            const hasTools = !!data.toolCalls && data.toolCalls.length > 0;

            if (!hasContent && !hasTools) {
                throw new Error("Se requiere contenido y/o herramientas!");
            }

            const body: AssistantMessage = {
                role: "assistant",
                name: data.name,
                content: data.content,
                toolCalls: hasTools ? data.toolCalls : undefined,
            };

            await execUpdate({ ...body, id: message.id, chatId: message.chat_id });
            return true;
        } catch (error) {
            notify.http(error);
            return false;
        }
    };

    return (
        <FormModal
            title="Actualizar mensaje del asistente"
            buttonPositive="Guardar"
            dialogProps={{ maxWidth: "xs", fullWidth: true }}
            onClose={onClose}
            onSubmit={handleSubmit}
            open={open}>
            <Box>
                <TextField
                    type="text"
                    name="name"
                    label="Nombre (opcional)"
                    value={data.name || ''}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                />
                <TextField
                    type="text"
                    name="content"
                    label="Contenido"
                    value={data.content || ''}
                    onChange={(e) => setData({ ...data, content: e.target.value })}
                    variant="outlined"
                    margin="dense"
                    minRows={3}
                    maxRows={5}
                    size="small"
                    multiline
                    fullWidth
                />
                <UpdateAssistantTools
                    tools={data.toolCalls}
                    onSubmit={(tools) => setData((prev) => ({ ...prev, toolCalls: tools }))} />
            </Box>
        </FormModal>
    );
}
