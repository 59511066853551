import { ComponentType, useState } from "react";
import { Box, Paper, Stack, Typography } from "@mui/material";
import moment from "moment";

interface MessageBase {
    id: number;
    role: string;
    name?: string | undefined;

    createdAt: string;
}

interface MessageBubbleProps<Message extends MessageBase> {
    message: Message;
    sender: Message['role'];
    TitleComponent: ComponentType<{ message: Message }>;
    ContentComponent: ComponentType<{ message: Message }>;
    AvatarComponent?: ComponentType<{ message: Message }>;
    HoverViewComponent?: ComponentType<{ message: Message }>;
}

export function MessageBubble<T extends MessageBase>({
    message, sender,
    TitleComponent, ContentComponent,
    AvatarComponent, HoverViewComponent,
}: MessageBubbleProps<T>) {
    const isSent = message.role === sender;
    const [isHovered, setIsHovered] = useState<boolean>(false);

    return (
        <Stack
            key={message.id}
            direction="row"
            spacing={1}
            flexDirection={isSent ? "row-reverse" : "row"}
        >
            {
                !isSent && (AvatarComponent && <AvatarComponent message={message} />)
            }
            <Box
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{ maxWidth: '60%', minWidth: 'auto' }}
            >
                <Typography variant="caption">
                    <TitleComponent message={message} />
                </Typography>

                <Box sx={{ position: 'relative' }}>
                    <Paper sx={{
                        p: 1.25,
                        borderRadius: '8px',
                        borderTopRightRadius: isSent ? 0 : '8px',
                        borderTopLeftRadius: isSent ? '8px' : 0,
                        backgroundColor: isSent ? 'primary.main' : 'background.paper',
                        color: isSent ? 'primary.contrastText' : 'text.primary',
                    }}>
                        <Stack spacing={.5}>
                            <ContentComponent message={message} />
                            <Typography
                                variant="caption"
                                sx={{ textAlign: 'right', fontWeight: 300, fontSize: '.7rem' }}
                            >
                                {moment(message.createdAt).format('hh:mm a')}
                            </Typography>
                        </Stack>
                    </Paper>
                    {
                        isHovered &&
                        HoverViewComponent &&
                        <HoverViewComponent message={message} />
                    }
                </Box>
            </Box>
        </Stack>
    );
}