import { Event } from "types/Event";
import { AdminAPI } from ".";
import { User } from "types/User";

export const injectUser = (api: AdminAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        findUser: build.query<User[], User.Find>({
            query: (args) => {
                const params: User.Find = { document: args.document };
                if (args.documentType) {
                    params.documentType = args.documentType;
                }

                return ({
                    method: "GET",
                    url: `/users/find`,
                    params
                })
            },
            providesTags: (result, err, it) => ([{ type: "User", id: `${it.documentType}:${it.document}` }]),
        }),
        retrieveUser: build.query<User, number>({
            query: (id) => ({
                method: "GET",
                url: `/users/${id}`,
            }),
            providesTags: (result, err, id) => ([{ type: "User", id }]),
        }),
        retrieveUserEvents: build.query<Event[], number>({
            query: (id) => ({
                method: "GET",
                url: `/users/${id}/events`,
            }),
            providesTags: (result, err, id) => ([
                { type: "User", id: `Event_${id}` },
                ...(result || []).map(({ id }) => ({ type: "Event", id: id } as const))
            ]),
        }),
    }),
    overrideExisting: "throw",
});