import { FormControl, InputLabel, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import { ExecuteFunction } from "components/ExecuteFunction/ExecuteFunction";
import { FormModal } from "components/Modal";
import { useNotification } from "contexts/NotificationContext";
import { useEffect, useMemo, useState } from "react";
import { useCreateTrainMessageMutation, useLazyRetrieveTrainMessagesQuery } from "services/api/assistant";
import { AssistantMessage, ToolMessage } from "types/Message";

interface CreateToolProps {
    open: boolean;
    onClose: () => void;
    chatId: number;
}

export function CreateTool(props: CreateToolProps) {
    const open = useMemo(() => props.open, [props.open]);
    const handleClose = useMemo(() => props.onClose, [props.onClose]);
    const chatId = useMemo(() => props.chatId, [props.chatId]);

    const [execCreate] = useCreateTrainMessageMutation();
    const [trigger, { data: messages, }] = useLazyRetrieveTrainMessagesQuery();
    const notify = useNotification();

    const [toolCallId, setToolCallId] = useState<AssistantMessage.Tool['id']>();
    const [options, setOptions] = useState<AssistantMessage.Tool[]>([]);
    const option = useMemo(() => options.find(({ id }) => (id === toolCallId)), [options, toolCallId]);
    const [currentResult, setResult] = useState<unknown>();


    useEffect(() => {
        if (chatId)
            trigger(chatId);
    }, [chatId, trigger]);

    useEffect(() => {
        if (!messages) return;

        const getAssitantTools = (): AssistantMessage.Tool[] => messages
            .filter((it) => it.role === "assistant" && (it.toolCalls || []).length > 0)
            .flatMap((it) => it.toolCalls || []);

        const getToolResponses = (): ToolMessage[] => messages
            .filter((it) => it.role === "tool")
            .map((it) => it as ToolMessage);

        const responses = getToolResponses();

        const tools = getAssitantTools().filter(toolCall =>
            !responses.some(it => it.toolCallId === toolCall.id)
        );
        setOptions(tools);
    }, [messages]);


    const handleSubmit = async (_: { [key: string]: FormDataEntryValue; }) => {
        try {
            if (!toolCallId)
                throw new Error("El tool_call_id es requerido!");
            if (!currentResult)
                throw new Error("El contenido es requerido!");

            const message: ToolMessage = {
                role: "tool",
                toolCallId: toolCallId,
                content: JSON.stringify(currentResult),
            }

            await execCreate({ ...message, chatId: chatId });
            return true;
        } catch (error) {
            notify.http(error);
            return false;
        }
    }

    return (
        <FormModal
            title="Nueva herramienta."
            buttonPositive="Guardar"
            dialogProps={{ maxWidth: "xs", fullWidth: true }}
            onClose={handleClose}
            onSubmit={handleSubmit}
            open={open}>
            <FormControl fullWidth margin="dense">
                <InputLabel id="tool_call_id-label">ID de llamada de herramienta</InputLabel>
                <Select
                    labelId="tool_call_id-label"
                    id="tool_call_id"
                    label="ID de llamada de herramienta"
                    value={toolCallId}
                    onChange={(e) => setToolCallId(e.target.value)}
                    margin="dense"
                >
                    {
                        options.map(({ id, type, function: func }) => (
                            <MenuItem value={id}>
                                <ListItemText
                                    primary={id}
                                    secondary={
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {`${type} ${func.name}()`}
                                        </Typography>
                                    }
                                />
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <ExecuteFunction
                function={option?.function.name}
                arguments={option?.function.arguments}
                onResult={setResult}
            />
        </FormModal >
    )
}