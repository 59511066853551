import { CreateNewFolder, ExpandLess, ExpandMore, Folder, FolderOpen, } from "@mui/icons-material";
import { Box, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Toolbar, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { TrainCase, TrainChat, TrainSubType, TrainType } from "types/Train";
import { useRetrieveTrainTypesQuery } from "services/api/assistant";
import { SubTypes } from "../subtypes";
import { TypeCreate } from "./TypeCreate";
import { TypeMenu } from "./TypeMenu";

interface TypesProps {
    selected?: TrainType;
    onChange?: (value?: TrainType) => void;
    selectedSubType?: TrainSubType;
    onChangeSubtype?: (value?: TrainSubType) => void;
    selectedCase?: TrainCase;
    onChangeCase?: (value?: TrainCase) => void;
    selectedChat?: TrainChat;
    onChangeChat?: (value?: TrainChat) => void;
}

export function Types({
    selected, onChange,
    selectedSubType, onChangeSubtype,
    selectedCase, onChangeCase,
    selectedChat, onChangeChat,
}: TypesProps) {
    const [hovered, setHovered] = useState<number | null>(null);
    const [create, setCreate] = useState<boolean>(false);
    const { data } = useRetrieveTrainTypesQuery();


    return (
        <Paper>
            <Toolbar
                id="types-toolbar"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 0,
                    bgcolor: 'background.paper'
                }}
            >
                <Typography variant="h6" component="div">
                    Tipo de evento
                </Typography>
            </Toolbar>
            <Box sx={{
                height: "72vh",
                overflow: "auto",
                px: 2,
            }}>
                <List component="div" disablePadding dense>
                    <ListItemButton onClick={() => setCreate(true)}>
                        <ListItemIcon sx={{ minWidth: 24, minHeight: 24, mr: 2 }}>
                            <CreateNewFolder />
                        </ListItemIcon>
                        <ListItemText primary={"Nuevo"} secondary={"Crear tipo"} />
                    </ListItemButton>

                    {data?.map((it) => (
                        <Fragment key={it.id}>
                            <ListItem
                                disablePadding
                                secondaryAction={hovered === it.id && <TypeMenu value={it} />}
                                onMouseEnter={() => setHovered(it.id)}
                                onMouseLeave={() => setHovered(null)}
                            >
                                <ListItemButton
                                    selected={selected?.id === it.id}
                                    onClick={() => onChange?.(it)}
                                >
                                    <ListItemIcon sx={{ minWidth: 24, minHeight: 24, mr: 2 }}>
                                        {selected?.id === it.id ? <FolderOpen /> : <Folder />}
                                    </ListItemIcon>
                                    <ListItemText primary={it.name} secondary={it.description} />
                                    {(hovered !== it.id) && (selected && selected.id === it.id ? <ExpandLess /> : <ExpandMore />)}
                                </ListItemButton>
                            </ListItem>
                            <Collapse in={selected?.id === it.id} timeout="auto" unmountOnExit>
                                <SubTypes
                                    trainType={selected}
                                    selected={selectedSubType}
                                    onChange={onChangeSubtype}
                                    selectedCase={selectedCase}
                                    onChangeCase={onChangeCase}
                                    selectedChat={selectedChat}
                                    onChangeChat={onChangeChat}
                                />
                            </Collapse>
                        </Fragment>
                    ))}
                </List>
            </Box>
            {create && <TypeCreate open={create} onClose={() => setCreate(false)} />}
        </Paper>
    );
}
